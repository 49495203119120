import { Component } from '@angular/core';

@Component({
  selector: 'app-farm-nav',
  templateUrl: './farm-nav.component.html',
  styleUrls: ['./farm-nav.component.scss']
})
export class FarmNavComponent {

}
