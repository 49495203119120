import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { GlobalEnums, GlobalFunctions, GlobalParams } from '../../../global';
import { AddUXRequest } from '../../../model/service/AddUXRequest';
import { UI } from '../../../tools/UI';
import { UXService } from '../../services/http/UX.service';
import { myPlanLargeVerticalComponent } from '../my_plan_large_vertical/my_plan_large_vertical.component';

@Component({
  selector: 'myPlanPage',
  templateUrl: './my_plan_page.component.html',
  styleUrls: ['./my_plan_page.component.css']
})

@Injectable()
export class myPlanPageComponent implements OnInit {  
  constructor(private resolver: ComponentFactoryResolver, private uxService: UXService, private activatedRoute : ActivatedRoute, private headerService : HeaderService) { }

  page: any = "MyPlan";

  @ViewChild('myPlan1_Placeholder', { read: ViewContainerRef })
  public myPlan1_Placeholder: ViewContainerRef;

  @ViewChild('myPlan2_Placeholder', { read: ViewContainerRef })
  public myPlan2_Placeholder: ViewContainerRef;

  @ViewChild('myPlan3_Placeholder', { read: ViewContainerRef })
  public myPlan3_Placeholder: ViewContainerRef;

  ngOnInit(): void {
    if (GlobalFunctions.GetLogUserExperience()) {
      let addUXRequest: AddUXRequest = new AddUXRequest();
      addUXRequest.IdPage = GlobalEnums.Page.MyPlan;
      addUXRequest.IdPlatform = GlobalParams.IdPlatform;

      this.uxService.SaveUX(addUXRequest).subscribe(() => {
      });
    }
    this.setHeaderTitle(); 
  }

  ngAfterViewInit() {
    this.LoadData();
  }

  LoadData() {
    // Affichage des offres disponibles
    this.LoadPlan(1);
    this.LoadPlan(2);
    this.LoadPlan(3);
  }

  LoadPlan(planId: number) {
    //if (this.IdProfil != 4 && planId == this.IdProfil) {
    //  return;
    //}

    let factory: ComponentFactory<myPlanLargeVerticalComponent> = this.resolver.resolveComponentFactory(myPlanLargeVerticalComponent);
    let myPlan: ComponentRef<myPlanLargeVerticalComponent>;

    switch (planId) {
      case 1:
        myPlan = this.myPlan1_Placeholder.createComponent(factory);
        break;
      case 2:
        myPlan = this.myPlan2_Placeholder.createComponent(factory);
        break;
      case 3:
        myPlan = this.myPlan3_Placeholder.createComponent(factory);
        break;
    }

    myPlan.instance.planId = planId;
    myPlan.instance.planName = GlobalFunctions.GetPlanNameFromId(planId);
    myPlan.instance.backgroundColorClass = "backgroundColor_Plan" + planId;
  }

  SetCurrentPage(id: GlobalEnums.SubComponentEnum): void {
    UI.GlobalFunctionsUI.SetCurrentPage(id);
  }

  setHeaderTitle() {
    this.headerService.setTitle("Mon abonnement"); 
  }
}
