import { Component, Injectable, Input, OnInit, Output } from '@angular/core';
import { ExpertOpinion } from "../../../../model/ExpertOpinion"; 
import { GetContributionExpertOpinionRequest } from '../../../../model/service/GetContributionExpertOpinionRequest';
import { NotificationService } from '../../../shared/components/notifications/notification.service';
import { ContributionService } from '../../../services/http/contribution.service';
import { GlobalFunctions } from 'src/global';


@Component({
  selector: 'app-expert-opinion',
  templateUrl: './expert_opinion.component.html',
  styleUrls: ['./expert_opinion.component.scss']
})

@Injectable()
export class ExpertOpinionComponent implements OnInit {

  @Input() productId: number;
  @Input() expertOpinionSpecificProductId: number;

  iconProduct : string =""; 

  expertOpinion: ExpertOpinion = new ExpertOpinion();

  constructor(private notificationService: NotificationService, private contributionService: ContributionService) {

  }

  ngOnInit(): void {
    this.LoadData(this.productId, this.expertOpinionSpecificProductId);
    this.getIconBasedOnProductId(this.productId)

  }

  GetProductImage(): string {
    return GlobalFunctions.GetProductImage();
  }

  getProductId(): number {
    return this.productId
  }

  GetArrowTrendImageCssClass(value: number): string {
    let cssClass: string = "";
    let engrais: string = (this.productId == 9) ? "Engrais" : "";

    switch (value) {
      case 0:
        cssClass = "imgTrendDown" + engrais;
        break;
      case 2:
        cssClass = "imgTrendNeutral" + engrais;
        break;
      case 4:
        cssClass = "imgTrendUp" + engrais;
        break;
    }

    return cssClass;
  }

  GetBackgroundTrendImageCssClass(value: number): string {
    let cssClass: string = "";
    let engrais: string = (this.productId == 9) ? "Engrais" : "";

    switch (value) {
      case 0:
        cssClass = "bgTrendDown" + engrais;
        break;
      case 2:
        cssClass = "bgTrendNeutral" + engrais;
        break;
      case 4:
        cssClass = "bgTrendUp" + engrais;
        break;
    }

    return cssClass;
  }

  LoadData(productId: number, expertOpinionId: number) {
    let request: GetContributionExpertOpinionRequest = new GetContributionExpertOpinionRequest();
    request.ProductId = productId;
    request.ExpertOpinionId = expertOpinionId;

    this.contributionService.GetContributionExpertOpinion(request).subscribe({
      next: (value: ExpertOpinion) => {
        this.expertOpinion.title = ((productId === 9) ? "Engrais azotés - " : "") + (value != null) ? value.title : "";
        this.expertOpinion.text = value.text;
        this.expertOpinion.horizon1Label = value.horizon1Label;
        this.expertOpinion.horizon2Label = value.horizon2Label;
        this.expertOpinion.horizon1Trend = value.horizon1Trend;
        this.expertOpinion.horizon2Trend = value.horizon2Trend;

        this.expertOpinion.classImgTrendHorizon1 = this.GetArrowTrendImageCssClass(this.expertOpinion.horizon1Trend);
        this.expertOpinion.classImgTrendHorizon2 = this.GetArrowTrendImageCssClass(this.expertOpinion.horizon2Trend);

        this.expertOpinion.classBackgroundTrendHorizon1 = this.GetBackgroundTrendImageCssClass(this.expertOpinion.horizon1Trend);
        this.expertOpinion.classBackgroundTrendHorizon2 = this.GetBackgroundTrendImageCssClass(this.expertOpinion.horizon2Trend);

      },
      error: (err: Error) => {
        this.notificationService.ShowError(" Une erreur a eu lieu lors du chargement des avis de l'expert.", "");
        console.error(err)
      },
      complete: () => {
        // console.info('Expert opinion loaded')
      }
      })

  }


  getIconBasedOnProductId(cropId: number){
    switch(cropId){
      case 1:
        this.iconProduct = 'ble_tendre.png'; 
        break; 
      case 2:
        this.iconProduct = 'mais.png'; 
        break; 
      case 3:
        this.iconProduct = 'colza.png'; 
        break; 
      case 4:
        this.iconProduct = 'orge_fourragere.png'; 
        break; 
      case 5: 
        this.iconProduct = 'orge_brassicole.png'; 
        break; 
      case 6: 
        this.iconProduct = 'ble_tendre.png'; 
        break; 
      case 7: 
        this.iconProduct = 'tournesol.png'; 
        break; 
      case 8: 
        this.iconProduct = 'ble_dur.png'; 
        break; 
      case 9: 
        this.iconProduct = 'engrais.png'; 
        break; 

      

    }
  }
}
