<div class="notification-container">
  <div class="report-header-container" (click)="closePanel()">
    <h4>Recevoir les rapports par mail</h4>
    <mat-icon aria-hidden="false" aria-label="arrow up" [fontIcon]="toggleIcon"
      [style.display]="hideIconDesktop"></mat-icon>
  </div>


  <div class="report-content-container" [hidden]="isHidden">
    <form [formGroup]="reportNotificationForm" (ngSubmit)="onSubmit()">

      @for (item of values; track item) {
        <div class="fields-report-notification">
          @switch (item.idReportType) {
            @case (1) {
              <img src="../../../assets/images/svg/cow.svg" alt="logo vache" width="32">
            }
            @case (2) {
              <img src="../../../assets/images/svg/wheet.svg" alt="logo ble" width="32">
            }
            @case (3) {
              <img src="../../../assets/images/svg/usda.svg" alt="logo ble" width="32">
            }
            @default {
              <img src='{{ "../../../assets/images/svg/default_report_icon.svg"}}' alt="logo ble" width="32">
            }
          }
    
        <div class="label-field">
          <label>{{item.reportTypeName}}</label>
        </div>
        <div class="toggle-align">
          <mat-slide-toggle formControlName="{{item.idReportType}}" [ngModel]="item.isActivated" [checked]="item.isActivated"></mat-slide-toggle>
        </div>
      </div>
      }
      <hr>
      <div class="report-notification-action-container">
        <button type="submit" class="buttonOutlinedTextBoldSmall">
          Enregistrer
        </button>

      </div>


    </form>
  </div>

</div>