export namespace OS {
  export class GlobalFunctionsOS {
    public static AndroidOrIOS() {
      const userAgent = navigator.userAgent;

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'iOS';
      }

      return '';
    }

    public static OpenStore(storeId: number) {
      let urlToOpen: string = '';

      if (storeId == 1) {
        urlToOpen = "https://play.google.com/store/apps/details?id=com.agritel.captainFarmer&hl=fr_FR&gl=US";
      }
      else if (storeId == 2) {
        urlToOpen = "https://apps.apple.com/fr/app/captain-farmer/id1471492724";
      }

      window.open(urlToOpen, '_blank');
    }
  }
}
