@if (getIsConnected()) {
<div class="parent-container" [hidden]="isClose">
  <div class="badge-container">
    <div class="agritel-plus-container">
      <div class="agritel-plus-button" (click)="toggle()"
        [matTooltip]="isAgritelFarmerPlus ? 'Hotline':'Agritel Farmer Plus'" matTooltipPosition="above">
        <img [src]="image" alt="agritel plus logo" class="image-agritel-plus">
      </div>
      <div class="container-information-hotline" [@openClose]="isOpen ? 'open' : 'closed'" class="open-close-container">
        <div class="header-information-hotline">
          <p>Une question ?</p>
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close" (click)="toggle()"
            class="icon-close" matTooltip="Fermer" matTooltipPosition="above"></mat-icon>
        </div>
        <hr>
        @if (isAgritelFarmerPlus ) {
        <div class="text-container">
          <p class="text">{{secondaryText}} :</p>
          <a class="hotline-number" href="tel:+{{numero}}">{{numero}}</a>
          <div class="customer-service-container">

            <p> {{text}} :
              <b>
                <a class="link-number" href="tel:+{{hotlinePhoneNumber}}">{{hotlinePhoneNumber}}</a>
              </b>
            </p>
          </div>
        </div>
        } @else {
        <div class="customer-service-container">
          <p>{{text}}</p>
          <a class="hotline-number" href="tel:+">{{numero}}</a>
        </div>
        }
        <hr>
        <button class="hide-badge" color="primary" (click)="closeBadge()">Ne plus afficher la bulle</button>
      </div>
    </div>
  </div>
</div>
}