import { Component, Injectable, OnInit, Input } from '@angular/core';
import { GlobalFunctions} from '../../../global';
import { GetContributionFocusRequest } from '../../../model/service/GetContributionFocusRequest';
import { StringTools } from '../../../tools/StringTools';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { ContributionService } from '../../services/http/contribution.service';
import { Focus } from 'src/model/analysis/Focus';

@Component({
  selector: 'app-focus',
  templateUrl: './focus.component.html',
  styleUrls: ['./focus.component.scss']
})

@Injectable()
export class FocusDetailComponent implements OnInit {


  title: string = '';
  text: string = '';
  focusDate: string = '';
  focusImage: string = ''; 
  imageIsClicked: boolean = false;
  baseURL: string = GlobalFunctions.GetAnalysisImagesUrl();
  planHasExpired: boolean;
  @Input() productId : number ; 

  constructor(private notifyService: NotificationService, private contributionService: ContributionService, private companyService: CompanyService) {

  }

  ngOnInit(): void {
    this.companyService.PlanHasExpired().subscribe((data: boolean) => {
      this.planHasExpired = data;

      this.LoadData(this.productId);
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      }); 
  }

  ngAfterViewInit() {    
  }

  LoadData(productId: number) {
    let request: GetContributionFocusRequest = new GetContributionFocusRequest();
    request.ProductId = productId;

    this.contributionService.GetContributionFocus(request).subscribe((data : Focus) => {
      this.title = data.title;
      this.text = data.text;
      this.focusDate = data.date;

      let day = this.focusDate.substring(8, 10);
      let month = this.focusDate.substring(5, 7);
      let year = this.focusDate.substring(0, 4);
      this.focusDate = year + month + day;

      if (this.planHasExpired) {
        this.focusImage = StringTools.ConcatUrlEnsureSimpleSlash(this.baseURL, "/Focus_mock.jpg");
      }
      else {
        this.focusImage = StringTools.ConcatUrlEnsureSimpleSlash(this.baseURL, "/F_" + this.productId + "_" + this.focusDate + ".jpg");
      }      
    },
    err => {
      if (err.status !== 401) {
        this.notifyService.ShowError("Erreur", "");
      }
    });
  }
}
