import { Component, Injectable, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { GraphOptions } from '../../../../../../Model/GraphOptions';
import { GetHistoryPriceFuturParamsRequest } from '../../../../../../model/service/GetHistoryPriceFutureParamsRequest';
import { Connection } from '../../../../../../tools/Connection';
import { ProductQuotesTypeEnum } from '../../../../../../tools/enums';
import { Graph } from '../../../../../../tools/Graph';
import { HighchartsService } from '../../../../../services/charts/highcharts.service';
import { HighstockService } from '../../../../../services/charts/highstock.service';
import { QuotesService } from '../../../../../services/http/quotes.service';
import { PriceChartComponent } from '../price_chart.component';

@Component({
  selector: 'price_chart_futures',
  templateUrl: './price_chart_futures.component.html',
  styleUrls: ['./price_chart_futures.component.scss']
})

@Injectable()
export class PriceChartFuturesComponent extends PriceChartComponent implements OnInit {
  override dialogRef: MatDialogRef<PriceChartFuturesComponent>;
  buttonToggle: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  chart;

  @Input() isCandlestick: boolean;
  cssClass1w: string = this.defaultCssClassInterval + " " + this.cssClassIntervalFirst;
  cssClass1m: string = this.defaultCssClassInterval + " " + this.cssClassIntervalSelected;
  cssClass3m: string = this.defaultCssClassInterval;
  cssClass6m: string = this.defaultCssClassInterval;
  cssClassMax: string = this.defaultCssClassInterval;
  firstGraphLoad: boolean = true;

  constructor(private quotesService: QuotesService, _highchartsService: HighchartsService, _highstockService: HighstockService) {
    super(_highchartsService, _highstockService);

    this.productQuotesType = ProductQuotesTypeEnum.Future;
    this.isCandlestick = false;
  }

  buttonClick(): void {
    this.buttonToggle = !this.buttonToggle;
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  override ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let graphOptions: GraphOptions = new GraphOptions();
    graphOptions.interval = this.defaultInterval;
    graphOptions.isCandleStick = false;
    graphOptions.isFirstLoad = true;
    graphOptions.YAxisText = this.unit;

    this.RefreshGraph(graphOptions);
  }

  override RefreshGraph(graphOptions: GraphOptions) {
    let shouldCallEndpoint: boolean = false;

    if (this.firstGraphLoad == true || graphOptions.interval != this.currentInterval) {
      shouldCallEndpoint = true;
      this.firstGraphLoad = false;
    }

    this.currentInterval = graphOptions.interval;
    this.isCandlestick = graphOptions.isCandleStick;

    var title: string = '';

    this.graphTitle = this.productName + " " + this.textExpiry;
    title = this.graphTitle;

    const currentDate: Date = new Date();
    const startDate: Date = Graph.GlobalFunctionsGraph.GetStartDateForInterval(graphOptions.interval);
    let request: GetHistoryPriceFuturParamsRequest = new GetHistoryPriceFuturParamsRequest();

    request.Interval = graphOptions.interval;
    request.ExpiryCode = this.codeExpiry;
    request.Code = this.productCode;
    request.StartDate = startDate;
    request.EndDate = currentDate;

    if (shouldCallEndpoint) {
      this.quotesService.GetProductQuotesHistoryFuturesEuronext(request).subscribe({
        next: (data) => {
          this.data = data;

          if (this.multiplier && this.multiplier != 1) {
            this.data.forEach(d => {
              d.close = parseFloat((d.close * this.multiplier).toFixed(2));
              d.variation = parseFloat((d.variation * this.multiplier).toFixed(2));
              d.open = parseFloat((d.open * this.multiplier).toFixed(2));
              d.high = parseFloat((d.high * this.multiplier).toFixed(2));
              d.low = parseFloat((d.low * this.multiplier).toFixed(2));
            });
          }

          this.CreateChart(this.isCandlestick, title, graphOptions.interval, graphOptions.isFirstLoad, graphOptions.YAxisText);
        },
        error: () => {
          this.ClosePopup();
          document.body.style.overflowY = "hidden";
          Connection.GlobalFunctionsConnection.Logout();
        }
      });
    }
    else {
      this.CreateChart(this.isCandlestick, title, graphOptions.interval, graphOptions.isFirstLoad, graphOptions.YAxisText);
    }
  }

  CreateChart(isCandlestick: boolean, title: string, interval: string, isFirstLoad: boolean = true, YAxisText: string) {
    if (isCandlestick) {
      this.chart = this.highstockService.CreateChartCandlestick(title, this.data, YAxisText);
    }
    else {
      this.chart = this.highchartsService.CreateChartLinear(this.charts.nativeElement, this.data, this.graphTitle, true, interval, isFirstLoad, YAxisText);
    }
  }

  ExportGraph(event) {
    if (this.isCandlestick) {
      this.chart.exportChart();
    }
    else {
      this.chart.options.xAxis.categories = [];
      this.chart.exportChart();
    }
  }
}
