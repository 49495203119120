import { Component, Injectable, OnInit } from '@angular/core';
import { NotificationService } from '../notifications/notification.service';
import { CompanyService } from '../../../services/http/company.service';
import { LeftMenuService } from 'src/app/services/left-menu.component.service';
import { UserService } from 'src/app/services/http/user.service';

@Component({
  selector: 'leftMenu',
  templateUrl: './left_menu.component.html',
  styleUrls: ['./left_menu.component.scss']
})

@Injectable()
export class LeftMenuComponent implements OnInit {
  planHasExpired: boolean;
  numberOfNotification: number = 0;
  logoUrl: string = "../../../assets/images/logo/agritel-farmer/agritelColoredLogo.svg";

  constructor(private companyService: CompanyService, private leftMenuService: LeftMenuService, private userService: UserService, private notificationService: NotificationService) {
  }

  ngOnInit(): void {

    this.checkIfPlanHasExpired();
    this.leftMenuService.getNumberOfAlertsNotRead();
    this.updateNotificationInfo();
    this.userSubscriptedPlan();
  }

  checkIfPlanHasExpired() {
    this.companyService.PlanHasExpired().subscribe((data: boolean) => {
      this.planHasExpired = data;
    },
      (err: { status: number; }) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur", "");
        }
      });
  }

  updateNotificationInfo() {
    this.leftMenuService.notification.subscribe(updateNotification => {
      this.numberOfNotification = updateNotification

    })
  }


  userSubscriptedPlan() {
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        switch (value.idProfil) {
          case 1:
            this.logoUrl = "../../../assets/images/logo/agritel-farmer/agritelColoredLogo.svg";
            break;
          case 2:
          case 3:
          case 4:
            this.logoUrl = "../../../assets/images/logo/agritel-plus/Agritel_Farmer Plus_large _full colour.svg";
            break;
        }
      },
      error: (err) => {
        if (err !== 401) {
          this.notificationService.ShowError("", "Une erreur a eu lieu");

        }
      },
      complete: () => {},
    })
  }

}
