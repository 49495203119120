<div class="container-tabMyFarm">
  <div class="content">
    <div class="tabMyFarm_title">
      Mon exploitation
    </div>
    <div class="form-fields">

      <mat-form-field id="mat-form-field-TotalLandArea" >
        <mat-label>Surface totale</mat-label>
        <input matInput id="TotalLandArea" name="TotalLandArea" required="required" placeholder="Surface totale" type="text" value="">
      </mat-form-field>

      <mat-form-field id="mat-form-field-riskProfile" class="">
        <mat-label id="lblRiskProfile">Profil de risque</mat-label>
        <mat-select [(value)]="selectedRisk" disableOptionCentering  (selectionChange)="ChangeRisk($event.value)">
          @for (riskProfile of riskProfiles; track riskProfile) {
            <mat-option [value]="riskProfile.value">
              {{riskProfile.text}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field id="mat-form-field-ZipCode">
        <mat-label>Code postal</mat-label>
        <input matInput id="ZipCode" name="ZipCode" required="required" placeholder="Code postal" type="text" value="">
      </mat-form-field>

      <div class="">
        <button type="button" (click)="Save()" class=" buttonFullTextBold width_100">Sauvegarder</button>
      </div>
      <div class="">
        <img src="assets/images/champs_ble.png" class="imgChampBle" />
      </div>
    </div>
  </div>
