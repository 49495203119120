import { Injectable } from '@angular/core';
import { LoginInformations } from '../../../model/LoginInformations';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class ConnectionService extends Service {
  override controller = 'Connection';
  loginMethod: string = 'Login';
  validateAccountMethod: string = 'ValidateAccount';

  Login(request: LoginInformations) {
    let data: LoginInformations = this.HttpPost(this.controller, this.loginMethod, request, true);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  loginUser(request : LoginInformations){

    let data =  this.HttpPostGeneric(this.controller, this.loginMethod, request, true);

    JSON.parse(JSON.stringify(data))
    
    return data

  }

  ValidateAccount(validateKey: string): any {
    let data: any = this.HttpGet(this.controller, this.validateAccountMethod, "validateKey=" + validateKey);

    JSON.parse(JSON.stringify(data))

    return data;
  }  
}



