import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalParams } from "../../../global";
import { GlobalFunctionsToken } from "../../../tools/Token";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(GlobalParams.BYPASS_HTTPINTERCEPTOR) === true) {
      return next.handle(req).pipe();
    }
    else {
      const modifiedReq = req.clone({
        headers: req.headers.set('Audience', `${GlobalParams.Audience}`).set('Token', GlobalFunctionsToken.GetConnectionToken())
      });

      return next.handle(modifiedReq);
    }
  }
}
