<div class="notification-container">
  <div class="alert-header-container" (click)="closePanel()">
    <h4>Recevoir par SMS </h4>
    <mat-icon aria-hidden="false" aria-label="arrow up" [fontIcon]="toggleIcon" [style.display]="hideIconDesktop"></mat-icon>
  </div>
  
  <div class="alert-content-container" [hidden]="isHidden">
    <form  (ngSubmit)="onSubmit()">
      <div class="logos-fields-alert-notification">

        <div class="inputs">
          <!-- INPUTS SLIDDER -->
          <div class="all-alert-notification">
            @for (alert of allAlertSubscriptType; track alert) {
              <div class="fields-alert-notification"  >
                <div class="logo-alert-notification">
                  @switch (alert.alertsTypeId) {
                    @case (1) {
                      <img  src='{{ "../../../../../assets/images/icon-alert/flash.png"}}' alt="logo ble" width="32">
                    }
                    @case (2) {
                      <img src='{{ "../../../../../assets/images/svg/cow.svg"}}' alt="logo ble" width="32">
                    }
                    @case (3) {
                      <img src='{{ "../../../../../assets/images/icon-alert/epiblepenche.png"}}' alt="logo ble" width="32">
                    }
                    @default {
                      <img src='{{ "../../../../../assets/images/icon-alert/newsagritel.png"}}' alt="logo ble" width="32">
                    }
                  }
                </div>
                <div class="label-field">
                  <label>{{alert.alertType.label}}</label>
                </div>
                <div class="toggle-align">
                  <mat-slide-toggle [(ngModel)]="alert.isActivated" [name]="alert.alertType.label" [checked]="alert.isActivated" ></mat-slide-toggle>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <hr>
        <div class="alert-notification-action-container">
          <button type="submit" class="buttonOutlinedTextBoldSmall">
            Enregistrer
          </button>

        </div>

      </form>
    </div>
  </div>
