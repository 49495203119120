import { Component, Injectable, Input, OnInit } from '@angular/core';
import * as $ from 'jquery/dist/jquery.min.js';

@Component({
  selector: 'product_prices_summary',
  templateUrl: './product_prices_summary.component.html',
  styleUrls: ['./product_prices_summary.component.css']
})

@Injectable()
export class ProductPricesSummaryComponent   implements OnInit {
  @Input() productName: string = '';
  @Input() productType: number;
  @Input() tbodyId: string ='';

  constructor() {
  }

   ngOnInit(): void {    
  }

  ngAfterViewInit() {
    this.tbodyId = 'table_product_price_summary_body_' + this.productType;
    this.LoadData();
  }

  LoadData() {
    var data = [
    ];

    switch (this.productType) {
      case 1:
        data = [
          { "productName": "Déc. 23", "price": "235.75", "variation": "3.25" },
          { "productName": "Mars 24", "price": "242.25", "variation": "2.75" },
          { "productName": "Mai 24", "price": "245.75", "variation": "2.25" },
          { "productName": "Juil. 24", "price": "241.25", "variation": "1.50" },
          { "productName": "Sept. 24", "price": "244.50", "variation": "-1.50" },
          { "productName": "Nov. 24", "price": "244.50", "variation": "1.15" },
          { "productName": "Jan. 25", "price": "244.50", "variation": "3.05" },
          { "productName": "Mars 25", "price": "244.50", "variation": "0.35" },
          { "productName": "Mai 25", "price": "244.50", "variation": "1.70" },
          { "productName": "Juil. 25", "price": "244.50", "variation": "1.05" }
        ];
        break;
      case 2:
        data = [
          { "productName": "Déc. 23", "price": "234.75", "variation": "3.15" },
          { "productName": "Mars 24", "price": "241.25", "variation": "0.00" },
          { "productName": "Mai 24", "price": "244.75", "variation": "2.15" },
          { "productName": "Juil. 24", "price": "240.25", "variation": "1.40" },
          { "productName": "Sept. 24", "price": "243.50", "variation": "1.40" },
          { "productName": "Nov. 24", "price": "244.50", "variation": "2.05" },
          { "productName": "Jan. 25", "price": "244.50", "variation": "3.85" },
          { "productName": "Mars 25", "price": "244.50", "variation": "0.45" },
          { "productName": "Mai 25", "price": "244.50", "variation": "1.15" },
          { "productName": "Juil. 25", "price": "244.50", "variation": "0.25" }
        ];
        break;
      case 3:
        data = [
          { "productName": "Déc. 23", "price": "233.75", "variation": "3.05" },
          { "productName": "Mars 24", "price": "240.25", "variation": "2.55" },
          { "productName": "Mai 24", "price": "243.75", "variation": "0.00" },
          { "productName": "Juil. 24", "price": "239.25", "variation": "-1.30" },
          { "productName": "Sept. 24", "price": "242.50", "variation": "1.30" },
          { "productName": "Nov. 24", "price": "244.50", "variation": "1.25" },
          { "productName": "Jan. 25", "price": "244.50", "variation": "2.05" },
          { "productName": "Mars 25", "price": "244.50", "variation": "1.55" },
          { "productName": "Mai 25", "price": "244.50", "variation": "0.35" },
          { "productName": "Juil. 25", "price": "244.50", "variation": "1.30" }
        ];
        break;
    }

    var tbody = $("#" + this.tbodyId) as HTMLTableElement;    

    if (tbody) {
      var oddBGColor = 'FFFFFF';
      var evenBGColor = 'F3F5F6';
      var isLastRow = false;

      var that = this;
      
      $.each(data, function (i, item) {
        var bgColorRow:string = (i % 2 == 0) ? oddBGColor : evenBGColor;
        // var bgColorVariation: string = (item.variation < 0 ? "E00000" : (item.variation > 0 ? "00840D" : "0094FF"));
        var bgColorVariation: string = (item.variation < 0 ? "E00000" : (item.variation > 0 ? "00840D" : "00840D"));


        isLastRow = (i == data.length - 1);

        var variationSign:string = (item.variation < 0 ? "-" : (item.variation > 0 ? "+" : "="));

        var lastRowBorderLeft = (isLastRow ? "border-bottom-left-radius: 10px;" : "");
        var lastRowBorderRight = isLastRow ? "border-bottom-right-radius: 10px;" : "";

        var cellStyleDisplay: string = "";// (i < 5) ? "table-cell" : "none";
        var cellCssClass: string = (i < 5) ? "tdCellProductPriceVisible" : "tdCellProductPriceHidden";

        $("#table_product_price_" + that.productType +" > tbody").append("<tr id='" + (i+1) + "' style='background-color: #" + bgColorRow + ";'>" + 
          "<td class='" + cellCssClass + "' rowid='" + (i + 1) + "' style='" + cellStyleDisplay + ";" + lastRowBorderLeft + "'><label style='color:#000000'>" + item.productName + "</label>" + 
          "<td class='" + cellCssClass + "' rowid='" + (i + 1) + "' style='text-align:center;" + cellStyleDisplay + "'><label style='color:#000000;'>" + item.price + "</label>" + 
          "<td class='" + cellCssClass + "' rowid='" + (i + 1) + "' style='text-align:center;" + cellStyleDisplay + "'><label id='lblVariationSign_" + i + "' style='padding-left:6px;width:18px;color:#FFF;background-color:#" + bgColorVariation + ";border-top-left-radius:10px;border-bottom-left-radius:10px;font-size:1.0em;font-variant-numeric: tabular-nums;font-weight: 600;'>" + variationSign + "</label>" +
            "<label style='color:#FFF;width:43px;border-top-right-radius:10px;padding-right:8px;border-bottom-right-radius:10px;;font-size:1.0em;font-variant-numeric: tabular-nums;font-weight: 600;background-color:#" + bgColorVariation + "'>" + item.variation.replace("-", "") + "</label>" +
          "<td class='" + cellCssClass + "' rowid='" + (i + 1) + "' style='text-align:center;cursor: pointer;" + cellStyleDisplay + ";" + lastRowBorderRight + "'><img title='Graphique' src='../../../assets/images/graph.png' /></tr>");
      });
    }
  }
}
