import { GlobalParams } from "../global";

export namespace Payment {
  export class GlobalFunctionsPayment {
    public static GetPaymentLink(planId: number): string {
      switch (planId) {
        case 1:
          return GlobalParams.plan1StripePaymentURL;
        case 2:
          return GlobalParams.plan2StripePaymentURL;
        case 3:
          return GlobalParams.plan3StripePaymentURL;
        default:
          return "";
      }
    }

    public static SetPaymentLink(planId: number, paymentLink: string) {
      switch (planId) {
        case 1:
          GlobalParams.plan1StripePaymentURL = paymentLink;
          break;
        case 2:
          GlobalParams.plan2StripePaymentURL = paymentLink;
          break;
        case 3:
          GlobalParams.plan3StripePaymentURL = paymentLink;
          break;
      }
    }    
  }
}
