import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoginInformations } from '../../../model/LoginInformations';
import { OS } from '../../../tools/OS';
import { UI } from '../../../tools/UI';
import { User } from '../../../tools/User';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ConnexionService } from '../../services/connexion/connexion.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

@Injectable()
export class LoginComponent implements OnInit {
  isLocal: boolean = false;

  storeCssClassAndroid = '';
  storeCssClassIOS = '';

  isLoginServiceError = false;
  isSuccessful = true;
  isSignUpFailed = false;
  errorMessage = '';
  token = '';
  hide: boolean = true;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })
  constructor(private connexionService: ConnexionService, private notifyService: NotificationService, private cookieService: CookieService) {
  }

  ngOnInit(): void {
    let osType = OS.GlobalFunctionsOS.AndroidOrIOS().toLowerCase();

    if (osType == 'android') {
      this.storeCssClassAndroid = '';
      this.storeCssClassIOS = 'hide';
    }
    else if (osType == 'ios') {
      this.storeCssClassAndroid = 'hide';
      this.storeCssClassIOS = '';
    }

    this.token = this.cookieService.get("token").trim();

    UI.GlobalFunctionsUI.SetContainerClass();

    if (this.token != "") {
      User.GlobalFunctionsUser.SetIsConnected(true);
    }

    UI.GlobalFunctionsUI.SetShowLegalMentions(true);
  }

  showDesktopSite() {
    var clientWidth = document.documentElement.clientWidth;
    var scale = (100 / clientWidth).toFixed(2);

    var mvp = document.getElementById('metaViewport');
    mvp.setAttribute('content', 'width=1024,initial-scale=' + scale);

    return false;
  }

  login() {
    let request: LoginInformations = new LoginInformations();
    request.Email = this.loginForm.value.email;
    request.Password = this.loginForm.value.password;

    this.connexionService.Login(request);
  };

  OpenStore(storeId: number) {
    OS.GlobalFunctionsOS.OpenStore(storeId);
  }
}
