export class Configuration {
  readonly websiteBaseURL!: string;
  readonly apiEndpoint!: string;
  readonly analysisImagesUrl!: string;
  readonly advisorsImagesUrl!: string;
  readonly logUserExperience!: boolean;
  readonly showToggleTheme!: boolean;
  readonly environment!: string;
  readonly payment_link_URL_1_garder_le_cap!: string;
  readonly payment_link_URL_2_ma_recommandation!: string;
  readonly payment_link_URL_3_face_a_face!: string;
  readonly forceHttps: boolean;

  readonly advisorInformationAgritelFarmerPlus: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };
  readonly advisorInformationAgritelFarmer: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  }
} 
