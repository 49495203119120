import { HttpContextToken } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums, GlobalFunctions, GlobalParams } from '../../../global';
import { Company } from 'src/model/Company';
import { User } from 'src/model/users/User';
import { Dates } from '../../../tools/Date';
import { UI } from '../../../tools/UI';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ConnexionService } from '../../services/connexion/connexion.service';
import { UserService } from '../../services/http/user.service';

@Component({
    selector: 'signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})

@Injectable()
export class SignupComponent implements OnInit {
  form: FormGroup | undefined;

  RaisonSociale = '';
  FirstName = '';
  LastName = '';
  Email = '';
  Mobile = '';
  Password = '';
  PasswordConfirm = '';
  AcceptConditions: boolean;
  AcceptDataUsage: boolean;
  hidePassword : boolean = true; 
  hidePasswordConfirm : boolean = true; 

  errorMessage = '';

  currentPageId = UI.GlobalFunctionsUI.GetCurrentPage();

  constructor(private userService: UserService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    // $('signup .mat-form-field-infix').each(function () {
    //   $(this).addClass('mat-form-field-infix-signup');
    // });

    // $('signup .mat-form-field').each(function () {
    //   $(this).addClass('mat-form-field-signup');
    // });
  }
  
  // AddBorders(id) {
  //   var element = document.getElementById(id);
  //   element.classList.add("mat-form-field-focus");
  // }

  // RemoveBorders(id) {
  //   var element = document.getElementById(id);
  //   element.classList.remove("mat-form-field-focus");
  // }

  lblCondition_click() {
    $('#AcceptConditions').click();
  }

  OpenLoginPage() {
    document.body.style.overflowY = "hidden";
    this.SetCurrentPage(GlobalEnums.SubComponentEnum.login);
  }

  SignUp() {
    if (!this.ValidateForm()) {
      return;
    }

    let request: User = new User();

    request = new User();
    request.id = GlobalParams.EmptyGuid;
    request.email = this.Email.toLowerCase().trim();
    request.password = this.Password;
    request.firstName = this.FirstName;  
    request.lastName = this.LastName;
    request.phone = this.Mobile;
    request.password = this.Password;

    const dateBegin: Date = new Date();
    let dateEnd: Date = dateBegin;
    dateEnd = Dates.GlobalFunctionsDates.AddDays(dateBegin, 15); // 15 jours de durée de validaté de la période d'essai
    
    request.company = <Company>({
      address1: '',
      address2: '',
      alertMode: '',
      alerts: 0,
      city: '',
      country: '',
      createdAt: new Date(),
      id: 0,
      isModeSecure: false,
      name: this.RaisonSociale,
      phone: this.Mobile,
      products: 0,
      provenance: '',
      rightBegin: dateBegin,
      rightEnd: dateEnd,
      rightLevel: 0,
      sageCodeTiers: '',
      stripeId: '',
      surface: 0,
      tempLevel: 0,
      TVANum: '',
      updatedAt: new Date(),
      zipCode: ''
    });

    GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => true);

    this.userService.SignUp(request).subscribe((data) => {
      if (data.responseType==2) {
        ConnexionService.SetCurrentPage(GlobalEnums.SubComponentEnum.trialPeriodRenewed);
        this.notifyService.ShowSuccess("Votre offre d'essai de 15 jours a été activée", "");
      }
      else {
        ConnexionService.SetCurrentPage(GlobalEnums.SubComponentEnum.confirmation);
        this.notifyService.ShowSuccess("Votre compte a été créé", "");
      }

      GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);
    },
      err => {
        if (err.error.detail == "ExistEmail") {
          this.notifyService.ShowError("Cet email est déjà utilisé", "");
        }
        else {
          this.notifyService.ShowError("Erreur lors de la création de votre compte", "");
        }

        GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);
      }
    );    
  }

  ValidateForm(): boolean {
    const trimmedPassword = this.Password.trim();
    const trimmedConfirmPassword = this.PasswordConfirm.trim();
    const hasAcceptedConditions: Boolean = $('#AcceptConditions').is(':checked');
    const hasAcceptedDataUsage: Boolean = $('#AcceptDataUsage').is(':checked');

    if (this.FirstName.trim().length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir un prénom", "");
      return false;
    }
    else if (this.LastName.trim().length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir un nom", "");
      return false;
    }
    else if (this.Email.trim().length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir un email", "");
      return false;
    }
    else if (!this.IsEmailCorrect()) {
      this.notifyService.ShowWarning("Le format de l'adresse email est incorrect", "");
      return false;
    }
    else if (this.Mobile.trim().length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir un mobile", "");
      return false;
    }
    else if (trimmedPassword.length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir un mot de passe", "");
      return false;
    }
    else if (trimmedPassword.length < 8 || trimmedPassword.length > 100) {
      this.notifyService.ShowWarning("Le mot de passe doit faire entre 8 et 100 caractères", "");
      return false;
    }
    else if (trimmedConfirmPassword.length == 0) {
      this.notifyService.ShowWarning("Veuillez saisir la confirmation du mot de passe", "");
      return false;
    }
    else if (trimmedPassword != trimmedConfirmPassword) {
      this.notifyService.ShowWarning("Le mot de passe et la confirmation du mot de passe doivent être identiques", "");
      return false;
    }
    else if (!hasAcceptedConditions) {
      this.notifyService.ShowWarning("Vous devez accepter les conditions d'utilisations", "");
      return false;
    }
    else if (!hasAcceptedDataUsage) {
      this.notifyService.ShowWarning("Vous devez accepter l'utilisation des données", "");
      return false;
    }

    return true;
  }

  OpenCGU() {
    window.open("https://www.captainfarmer.com/politique-confidentialite/", "_blank");
  }

  SetCurrentPage(id: GlobalEnums.SubComponentEnum): void {
    UI.GlobalFunctionsUI.SetCurrentPage(id);
  }

  IsEmailCorrect(): boolean {
    return (GlobalFunctions.ValidateEmail(this.Email.trim())) != null;
  };
}  
