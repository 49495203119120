import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums } from '../../../global';
import { Product } from '../../../model/Product';
import { UI } from '../../../tools/UI';

@Component({
  selector: 'product',
  template: '<ng-container></ng-container>',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

@Injectable()
export class ProductComponent   implements OnInit {
  form: FormGroup | undefined;

  public product: Product = new Product(GlobalEnums.TypeProductEnum.bleTendre, '', '');

  popup: string = '';
  isSelected: boolean = false;
  orangeBorderProduct: string = '';

  @Input()
  template;

  constructor() {
  }

   ngOnInit(): void {
    if (this.product.isPopup && UI.GlobalFunctionsUI.GetCurrentPage() == GlobalEnums.SubComponentEnum.myFarm) {
      this.popup = "_popup";
    }

    this.isSelected = this.product.isSelected;
  }

  ngAfterViewInit() {
    if (this.isSelected) {
      $("#ckProduct_" + this.product.typeProductEnum + this.popup).prop("checked", "checked");
    }
  }

  ClickCheckbox2(event) {
    if (event.target.type != "checkbox") {
      this.ClickCheckbox(event);
    }
  }

  ClickCheckbox(event) {
    var ckProduct = $("#ckProduct_" + this.product.typeProductEnum + this.popup);
    var isChecked: boolean = ckProduct.prop("checked");

    if (event.target.type != "checkbox") {
      ckProduct.prop("checked", (isChecked) ? "" : "checked");
    }

    isChecked = ckProduct.prop("checked");

    if (isChecked) {
      $("#divProduct_" + this.product.typeProductEnum + this.popup).addClass("orangeBorderProduct");
    }
    else {
      $("#divProduct_" + this.product.typeProductEnum + this.popup).removeClass("orangeBorderProduct");
    }    

    if (event.target.type == "checkbox") {
      event.stopPropagation();
    }
  }


}
