import { Component, Injectable, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalFunctions} from '../../../global';
import { GetContributionChartRequest } from '../../../model/service/GetContributionChartRequest';
import { StringTools } from '../../../tools/StringTools';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { ContributionService } from '../../services/http/contribution.service';
import { AnalysisComponent } from '../analysis/analysis.component';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})

@Injectable()
export class ChartDetailComponent  implements OnInit {
  analysisComponent: AnalysisComponent;

  title: string = '';
  text: string = '';
  chartDate: string = '';
  chartImage: string = ''
  baseURL: string = GlobalFunctions.GetAnalysisImagesUrl();
  planHasExpired: boolean;
  @Input() productId : number ; 

  constructor(private notifyService: NotificationService, private contributionService: ContributionService, private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.companyService.PlanHasExpired().subscribe(data => {
      this.planHasExpired = data;
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });  
  }

  ngAfterViewInit() {
    this.LoadData(this.productId);

  }

  LoadData(productId: number) {
      let request: GetContributionChartRequest = new GetContributionChartRequest();
      request.ProductId = productId;

      this.contributionService.GetContributionChart(request).subscribe((data: { title: string; text: string; date: string; }) => {
        this.title = data.title;
        this.text = data.text;
        // TODO : Get rid of JQUERY 
        if (this.text == null) {
          $("chartdetail").hide();
          return;
        }
        else {
          $("chartdetail").show();
        }

        this.chartDate = data.date;

        var day = this.chartDate.substring(8, 10);
        var month = this.chartDate.substring(5, 7);
        var year = this.chartDate.substring(0, 4);
        this.chartDate = year + month + day;

        if (this.planHasExpired) {
          this.chartImage = StringTools.ConcatUrlEnsureSimpleSlash(this.baseURL, "/Chart_mock.jpg");
        }
        else {
          this.chartImage = StringTools.ConcatUrlEnsureSimpleSlash(GlobalFunctions.GetAnalysisImagesUrl(),  "A_" + this.productId + "_" + this.chartDate + ".jpg");
        }        
      },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }
}
