@if (crop | async) {
  <div class="analysis-navigation">
    <ul class="crop-container">
      <li [hidden]="!(crop|async)?.bleTendre" class="crop-item">
        <a [routerLink]="['ble/tendre']" routerLinkActive="active-link" class="crop-link">
          <img src="../../../../../assets/images/icon-analysis/ble_tendre.png" alt="Logo blé tendre" width="32">
          <p class="crop-name"> Blé tendre </p>
        </a>
      </li>
      <li [hidden]="!(crop|async)?.colza" class="crop-item">
        <a [routerLink]="['colza']" routerLinkActive="active-link" class="crop-link">
          <img src="../../../../../assets/images/icon-analysis/colza.png" alt="Logo colza" width="32">
          <p class="crop-name">
            Colza
          </p>
        </a>
      </li>
      <li [hidden]="!(crop|async)?.ourgeFourragere" class="crop-item">
        <a [routerLink]="['orge/fourragere']" routerLinkActive="active-link" class="crop-link">
          <img src="../../../../../assets/images/icon-analysis/orge_fourragere.png" alt="Logo Orge fourragère" width="32">
          <p class="crop-name"> Orge fourragère </p>
        </a>
      </li>
      <li [hidden]="!(crop|async)?.mais" class="crop-item">
        <a [routerLink]="['mais']" routerLinkActive="active-link" class="crop-link">
          <img src="../../../../../assets/images/icon-analysis/mais.png" alt="Logo mais" width="32">
          <p class="crop-name"> Mais</p>
        </a>
      </li>
      <li [hidden]="!(crop|async)?.orgeBrassPrin" class="crop-item">
        <a [routerLink]="['orge/brassicole/printaniere']" routerLinkActive="active-link" class="crop-link">
          <img src="../../../../../assets/images/icon-analysis/orge_brassicole.png" alt="Orge brassicole printaniere"
            width="32">
            <p class="crop-name">Orge brassicole prin</p>
          </a>
        </li>
        <li [hidden]="!(crop|async)?.tournesol" class="crop-item">
          <a [routerLink]="['tournesol']" routerLinkActive="active-link" class="crop-link">
            <img src="../../../../../assets/images/icon-analysis/tournesol.png" alt="Logo tournesol" width="32">
            <p class="crop-name"> Tournesol</p>
          </a>
        </li>
        <li [hidden]="!(crop|async)?.bleDur" class="crop-item">
          <a [routerLink]="['ble/dur']" routerLinkActive="active-link" class="crop-link">
            <img src="../../../../../assets/images/icon-analysis/ble_dur.png" alt="Logo blé dur" width="32">
            <p class="crop-name">
              Blé dur
            </p>
          </a>
        </li>
        <li [hidden]="!(crop|async)?.engrais" class="crop-item">
          <a [routerLink]="['engrais']" routerLinkActive="active-link" class="crop-link">
            <img src="../../../../../assets/images/icon-analysis/engrais.png" alt="Logo engrais" width="32">
            <p class="crop-name">
              Engrais
            </p>
          </a>
        </li>
        <li class="crop-item-button">
          <button mat-mini-fab (click)="openDialogProducts()" class="crop-button" aria-label="Example icon button with a delete icon">
            <mat-icon>add</mat-icon>
          </button>
          <p class="crop-name">Gérer</p>
        </li>
      </ul>
    </div>
  }