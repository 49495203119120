import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { GlobalFunctions, GlobalParams } from '../global';
import { UI } from '../tools/UI';
import { User } from '../tools/User';
import { Configuration } from './configuration';
import { HeaderService } from './services/header/header.component.service';
import { NavigationStart, Router } from '@angular/router';
import { Payment } from 'src/tools/Payment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Agritel Farmer';
  websiteBaseURL: string = '';
  apiEndpoint: string = '';
  advisorsImagesUrl: string = '';
  analysisImagesUrl: string = '';
  logUserExperience: boolean = false;
  showToggleTheme: boolean = false;
  forceHttps: boolean = false;
  environment: string = '';
  payment_link_URL_1_garder_le_cap: string = '';
  payment_link_URL_2_ma_recommandation: string = '';
  payment_link_URL_3_face_a_face: string = '';

  advisorInformationAgritelFarmer: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };
  advisorInformationAgritelFarmerPlus: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };

  constructor(public readonly config: Configuration, @Inject(DOCUMENT) private document: Document, private headerService: HeaderService, private router: Router) {
    this.websiteBaseURL = config.websiteBaseURL;
    this.apiEndpoint = config.apiEndpoint;
    this.advisorsImagesUrl = config.advisorsImagesUrl;
    this.analysisImagesUrl = config.analysisImagesUrl;
    this.logUserExperience = config.logUserExperience;
    this.showToggleTheme = config.showToggleTheme;
    this.environment = config.environment;
    this.payment_link_URL_1_garder_le_cap = config.payment_link_URL_1_garder_le_cap;
    this.payment_link_URL_2_ma_recommandation = config.payment_link_URL_2_ma_recommandation;
    this.payment_link_URL_3_face_a_face = config.payment_link_URL_3_face_a_face;
    this.forceHttps = config.forceHttps;
    this.advisorInformationAgritelFarmer = config.advisorInformationAgritelFarmer;
    this.advisorInformationAgritelFarmerPlus = config.advisorInformationAgritelFarmerPlus;

    GlobalFunctions.SetWebsiteBaseURL(this.websiteBaseURL);
    GlobalFunctions.SetApiEndpoint(this.apiEndpoint);
    GlobalFunctions.SetAdvisorsImagesUrl(this.advisorsImagesUrl);
    GlobalFunctions.SetAnalysisImagesUrl(this.analysisImagesUrl);
    GlobalFunctions.SetLogUserExperience(this.logUserExperience);
    GlobalFunctions.SetShowToggleTheme(this.showToggleTheme);
    GlobalFunctions.SetForceHttps(this.forceHttps);
    GlobalFunctions.SetEnvironment(this.environment);
    Payment.GlobalFunctionsPayment.SetPaymentLink(1, this.payment_link_URL_1_garder_le_cap);
    Payment.GlobalFunctionsPayment.SetPaymentLink(2, this.payment_link_URL_2_ma_recommandation);
    Payment.GlobalFunctionsPayment.SetPaymentLink(3, this.payment_link_URL_3_face_a_face);

    GlobalFunctions.SetAdvisorInformationAgritelFarmer(this.advisorInformationAgritelFarmer);

    GlobalFunctions.SetAdvisorInformationAgritelFarmerPlus(this.advisorInformationAgritelFarmerPlus);

  }

  ngOnInit() {
    if (!User.GlobalFunctionsUser.GetIsConnected()) {
      // document.body.style.overflowY = "hidden";
    }

    // Force redirection to Https if needed
    if (GlobalParams.ForceHttps == true && location.protocol == "http:")
      window.location.href = location.href.replace('http', 'https');

  }

  ngDoCheck() {
    this.redirectToSpecificPage();
  }

  GetIsConnected(): boolean {
    return User.GlobalFunctionsUser.GetIsConnected();
  }

  ToggleUserMenu() {
    UI.GlobalFunctionsUI.ShowHideMenu();
  }

  GetName(): string {
    return User.GlobalFunctionsUser.GetFirstName();
  }

  redirectToSpecificPage() {
    const nameLocalStorage = "redirectedUrl";
    this.router.events.forEach((events) => {
      if (events instanceof NavigationStart && events.url != "/connexion" && events.url != "/mot-de-passe") {
        localStorage.setItem(nameLocalStorage, events.url)
      }
    })
  }
}
