import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { reportHistory } from 'src/model/reports/reports';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { ReportService } from 'src/app/services/http/report.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(private headerService: HeaderService,private reportService: ReportService, private notificationService: NotificationService) {

  }

  reportsHistoryArray: reportHistory[] = [];

  ngOnInit(): void {
    this.setHeaderTitle();
    this.loadReports();
  }

  setHeaderTitle() {
    this.headerService.setTitle("Mes rapports");
  }

  loadReports() {
    this.reportService.GetHistoryReportsNumber().subscribe({
      next: (data: reportHistory[]) => {
        data.forEach((element) => {
          switch (element.idReportType) {
            case 1:
              element.imgName = "cow.svg";
              break;
            case 2:
              element.imgName = "wheet.svg";
              break;
            case 3:
              element.imgName = "usda.svg";
              break;
            default:
              element.imgName = "default_report_icon.svg"
          }

          this.reportsHistoryArray.push(element)

        })
      },
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur lors du chargement des rapports.", "Erreur")
        }
      },

      complete: () => {}
    })
  }

  /**
   * Download Reports function takes 2 arguments url and the name of the file. 
   * Fetches the URL of the PDF with the function fromFetch (RXJS). 
   * Response is type blob with the blob() function.
   * In the next() I create my link dynamically. 
   * @url 
   * @name 
   *  
   **/

  downloadReports(url: string, name: string) {
    fromFetch(url).pipe(
      switchMap((response) => response.blob())
    )
      .subscribe(
        {
          next: (value) => {
            let downloadURL = URL.createObjectURL(value);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = name;
            link.click();
            URL.revokeObjectURL(url);
          },
          error: (err: { status: number; }) => {
            if (err.status != 401) {
              this.notificationService.ShowError("Lors du téléchargement.", "Erreur")
            }
          },
          complete: () => console.info("Download completed")
        }
      )
  }
}