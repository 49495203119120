<div class="container-my_plan_selection">
  <div>
    <div>
      <label class="bigLabel">Votre offre {{expireRenew}} le</label>
      <label id="lblRightEnd">{{rightEnd}}</label>
    </div>
    @if (!planHasExpired) {
      <div>
        <ng-container #myPlanCurrentOffer_Placeholder></ng-container>
      </div>
    }
    @if (IdProfil!=3) {
      <div class="div_my_plan_text">
        <label class="lb_my_plan_text">
          L'accompagnement Agritel Farmer n'a pas de secret pour vous ?
        </label>
        <label class="lb_my_plan_text">
          Sélectionnez l'offre correspondante à vos besoins ou contactez votre conseiller
        </label>
      </div>
    }
  </div>
</div>

