import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularRuntimeConfigModule } from 'angular-runtime-config';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { OverlayModule } from '@angular/cdk/overlay';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';


/*** Material Angular Imports  ***/
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';


/*** End Material Angular Imports  ***/

import { ChangePasswordComponent } from './account/change_password/change_password.component';
import { ContactAdvisorComponent } from './account/contact_advisor/contact_advisor.component';
import { ExpiredPlanPopinComponent } from './account/expired_plan_popin/expired_plan_popin.component';
import { InformationNoticeComponent } from './account/information_notice/information_notice.component';
import { LoginComponent } from './account/login/login.component';
import { LoginTeaserComponent } from './account/login_teaser/login_teaser.component';
import { loginWithTeaserComponent } from './account/login_with_teaser/login_with_teaser.component';
import { MyAccountComponent } from './account/my_account/my_account.component';
import { MyInformationsComponent } from './account/my_informations/my_informations.component';
import { myPlanComponent } from './account/my_plan/my_plan.component';
import { myPlanLargeHorizontalComponent } from './account/my_plan_large_horizontal/my_plan_large_horizontal.component';
import { myPlanLargeVerticalComponent } from './account/my_plan_large_vertical/my_plan_large_vertical.component';
import { myPlanPageComponent } from './account/my_plan_page/my_plan_page.component';
import { myPlanSelectionComponent } from './account/my_plan_selection/my_plan_selection.component';
import { PasswordForgottenComponent } from './account/password_forgotten/password_forgotten.component';
import { PasswordForgottenWithTeaserComponent } from './account/password_forgotten_with_teaser/password_forgotten_with_teaser.component';
import { SignupComponent } from './account/signup/signup.component';
import { SignupTeaserComponent } from './account/signup_teaser/signup_teaser.component';
import { SignupWithTeaserComponent } from './account/signup_with_teaser/signup_with_teaser.component';
import { UserMenuComponent } from './account/user_menu/user_menu.component';
import { ValidateAccountComponent } from './account/validate_account/validate_account.component';
import { AnalysisComponent } from './analysis/analysis/analysis.component';
import { ChartDetailComponent } from './analysis/chart/chart.component';
import { DecryptDetailComponent } from './analysis/decrypt/decrypt_detail.component';
import { ExpertOpinionComponent } from './analysis/expert-opinion/expert_opinion/expert_opinion.component';
import { ExpertOpinionHeaderComponent } from './analysis/expert-opinion/expert_opinion_header/expert_opinion_header.component';
import { FocusDetailComponent } from './analysis/focus/focus.component';
import { OpinionAndStrategyComponent } from './analysis/opinion_and_strategy/opinion_and_strategy.component';
import { StrategyComponent } from './analysis/strategy/strategy.component';
import { StrategyHeaderComponent } from './analysis/strategy_header/strategy_header.component';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/interceptors/AuthInterceptor';
import { SpinnerInterceptor } from './shared/interceptors/spinner/spinner.interceptor';
import { TokenInterceptor } from './shared/interceptors/token-interceptor';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
import { Configuration } from './configuration';
import { ProductComponent } from './farm/product/product.component';
import { TabMyFarmComponent } from './farm/tab_my_farm/tab_my_farm.component';
import { TabMyProductsComponent } from './farm/tab_my_products/tab_my_products.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LeftMenuComponent } from './shared/components/left_menu/left_menu.component';
import { LegalMentionsComponent } from './shared/components/legal_mentions/legal_mentions.component';
import { IntervalSelectorComponent } from './quotes/component/charts/price_chart/interval_selector/interval_selector.component';
import { PriceChartComponent } from './quotes/component/charts/price_chart/price_chart.component';
import { PriceChartFuturesComponent } from './quotes/component/charts/price_chart/price_chart_futures/price_chart_futures.component';
import { PriceChartPhysicalComponent } from './quotes/component/charts/price_chart/price_chart_physical/price_chart_physical.component';
import { CMEQuotesComponent } from './quotes/component/CME_quotes/CME_quotes.component';
import { EuronextQuotesComponent } from './quotes/component/euronext_quotes/euronext_quotes.component';
import { PhysicalQuotesComponent } from './quotes/component/physical_quotes/physical_quotes.component';
import { ProductPricesSummaryComponent } from './quotes/component/product_prices_summary/product_prices_summary.component';
import { QuotesComponent } from './quotes/component/quotes_component.component';
import { QuotesPageComponent } from './quotes/quotes_page.component';
import { SafeHtmlPipe } from './shared/pipe/safeHtml';
import { KeypointsComponent } from './analysis/keypoints/keypoints.component';
import { ChartFooterComponent } from './quotes/component/charts/price_chart/chart_footer/chart_footer.component';
import { ProductTableEuronextComponent } from './quotes/component/product_table/product_table_euronext/product_table_euronext.component';
import { ProductTableCMEComponent } from './quotes/component/product_table/product_table_CME/product_table_CME.component';
import { ProductPriceLineComponent } from './quotes/component/product_price_line/product_price_line.component';
import { ReportComponent } from './account/report-components/report/report.component';
import { ReportNotificationComponent } from './account/report-components/report-notification/report-notification.component';
import { AccountNavComponent } from './account/account-nav/account-nav.component';
import { FarmNavComponent } from './farm/farm-nav/farm-nav.component';
import { PhysicalQuotesLineComponent } from './quotes/component/physical_quotes/physical_quotes_line/physical_quotes_line.component';
import { AlertComponent } from './account/alert-components/alert/alert.component';
import { AlertNotificationsComponent } from './account/alert-components/alert-notifications/alert-notifications.component';
import { AnalysisNavComponent } from './analysis/analysis-nav/analysis-nav.component';
import { FertilizerComponent } from './analysis/crops/fertilizer/fertilizer.component';
import { AvisComponent } from './analysis/avis/avis.component';

/** Replay Import **/
import { ReplayComponent } from './replay/replay.component';
import { ReplayNavComponent } from './replay/replay-nav/replay-nav.component';
import { MinuteChronoComponent } from './replay/minute-chrono/minute-chrono.component';
import { CompanyFarmersComponent } from './replay/company-farmers/company-farmers.component';
import { MarketPanoramicComponent } from './replay/market-panoramic/market-panoramic.component';
import { AgritelOverlayPlusComponent } from './agritel-overlay-plus/agritel-overlay-plus.component';

/** Services **/
import { HeaderService } from './services/header/header.component.service';
import { LeftMenuService } from './services/left-menu.component.service';

import { HighstockService } from './services/charts/highstock.service';
import { HighchartsService } from './services/charts/highcharts.service';

/** Crops Import **/
import { CornComponent } from './analysis/crops/corn/corn.component';
import { RapeComponent } from './analysis/crops/rape/rape.component';
import { SunflowerComponent } from './analysis/crops/sunflower/sunflower.component';
import { CommonWheatComponent } from './analysis/crops/common-wheat/common-wheat.component';
import { DurumWheatComponent } from './analysis/crops/durum-wheat/durum-wheat.component';
import { FeedBarleyComponent } from './analysis/crops/feed-barley/feed-barley.component';
import { MaltBarleyComponent } from './analysis/crops/malt-barley/malt-barley.component';
import { AgritelPlusComponent } from './agritel-plus/agritel-plus.component';
import { BaseComponent } from './base/base.component';




registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LegalMentionsComponent,
    SignupComponent,
    ValidateAccountComponent,
    HeaderComponent,
    MyAccountComponent,
    PasswordForgottenComponent,
    SpinnerOverlayComponent,
    // MyFarmComponent,
    TabMyProductsComponent,
    TabMyFarmComponent,
    StrategyHeaderComponent,
    LeftMenuComponent,
    ProductComponent,
    OpinionAndStrategyComponent,
    StrategyComponent,
    ExpertOpinionComponent,
    ExpertOpinionHeaderComponent,
    AnalysisComponent,
    DecryptDetailComponent,
    ChartDetailComponent,
    InformationNoticeComponent,
    UserMenuComponent,
    ChangePasswordComponent,
    MyAccountComponent,
    MyInformationsComponent,
    ContactAdvisorComponent,
    myPlanComponent,
    myPlanPageComponent,
    myPlanSelectionComponent,
    myPlanLargeHorizontalComponent,
    myPlanLargeVerticalComponent,
    ExpiredPlanPopinComponent,
    LoginTeaserComponent,
    loginWithTeaserComponent,
    SignupWithTeaserComponent,
    PasswordForgottenWithTeaserComponent,
    SignupTeaserComponent,
    SafeHtmlPipe,
    FocusDetailComponent,
    FooterComponent,
    QuotesPageComponent,
    PhysicalQuotesComponent,
    QuotesComponent,
    EuronextQuotesComponent,
    CMEQuotesComponent,
    ProductPricesSummaryComponent,
    KeypointsComponent,
    PriceChartComponent,
    PriceChartPhysicalComponent,
    PriceChartFuturesComponent,
    IntervalSelectorComponent,
    ChartFooterComponent,
    ProductTableEuronextComponent,
    ProductPriceLineComponent,
    ProductTableCMEComponent,
    ReportComponent,
    ReportNotificationComponent,
    AccountNavComponent,
    FarmNavComponent,
    PhysicalQuotesLineComponent,
    AlertComponent,
    AlertNotificationsComponent,
    AnalysisNavComponent,
    FertilizerComponent,
    AvisComponent,
    CornComponent,
    RapeComponent,
    SunflowerComponent,
    CommonWheatComponent,
    DurumWheatComponent,
    FeedBarleyComponent,
    MaltBarleyComponent,
    StrategyComponent,
    ReplayComponent,
    ReplayNavComponent,
    MinuteChronoComponent,
    CompanyFarmersComponent,
    MarketPanoramicComponent,
    AgritelOverlayPlusComponent,
    AgritelPlusComponent,
    FaqComponent,
    BaseComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AngularRuntimeConfigModule.forRoot(Configuration, {
      log: true,
      urlFactory: () => {
        return [
          `/assets/config/config.json`,
        ];
      },
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [TabMyFarmComponent,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR ' },
    HighchartsService,
    HighstockService,
    HeaderService,
    LeftMenuService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
