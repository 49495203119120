import { Injectable } from '@angular/core';
import { GlobalFunctions, GlobalParams } from '../../../global';
import { AddUXRequest } from '../../../model/service/AddUXRequest';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class UXService extends Service {
  override controller = 'UX';
  saveUX: string = 'SaveUX';

  SaveUX(addUXRequest: AddUXRequest): any {
      addUXRequest.IdUser = GlobalParams.EmptyGuid;
      addUXRequest.Environment = GlobalFunctions.GetEnvironment();

      let data: any = this.HttpPost(this.controller, this.saveUX, addUXRequest);

      JSON.parse(JSON.stringify(data))

      return data;      
  }
}



