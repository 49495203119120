import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/http/user.service';
import { NotificationService } from '../shared/components/notifications/notification.service';
import { User } from 'src/tools/User';
import { CookieService } from 'ngx-cookie-service';
import {
  trigger,
  state,
  style,
  animate,
  transition,

} from '@angular/animations';
@Component({
  selector: 'app-agritel-plus',
  templateUrl: './agritel-plus.component.html',
  styleUrls: ['./agritel-plus.component.scss'],
  animations:
    [trigger('openClose', [
      state('open', style({
        opacity: 1,
        maxWidth: '325px',
        backgroundColor: '#fff',
        color: '#4d4d4d',
        borderStyle: 'solid',
        borderColor: '#EF7D19',
        borderWidth: '2px',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
      })),
      state('closed', style({
        opacity: 0,
        display: 'none',
        maxWidth: '325px',
        backgroundColor: '#fff',
        color: '#4d4d4d',
        borderStyle: 'solid',
        borderColor: '#EF7D19',
        borderWidth: '2px',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',

      })),
      transition('open <=> closed', [
        animate('0.3s')
      ]),
    ]),]
})
export class AgritelPlusComponent implements OnInit {

  constructor(private userService: UserService, private notificationService: NotificationService, private cookieService: CookieService) { }

  isAgritelFarmerPlus: boolean = false;
  isOpen: boolean = false;
  isClose: boolean = this.cookieService.get("hiddenBadgeAFPlus") ? true : false;
  image: string = "../../assets/images/logo/agritel-plus/small_agritel_plus_logomark_full colour_rgb.png";
  numero: string = "07 66 39 29 79";
  hotlinePhoneNumber: string | null;
  text: string = "Une question concernant votre abonnement ? Contactez notre service commercial !";
  secondaryText: string = "";

  ngOnInit(): void {
    this.getUserIdProfil();
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  closeBadge() {
    const agritelPlusCookie: boolean = this.cookieService.check('hiddenBadgeAFPlus');
    const valueAgritelPlusCookie: string = this.cookieService.get("hiddenBadgeAFPlus");

    switch (this.isAgritelFarmerPlus) {
      case true:
        if (agritelPlusCookie === true && valueAgritelPlusCookie === 'true') {
          this.isClose = true;
        } else {
          this.cookieService.set("hiddenBadgeAFPlus", "true", { expires: 2, sameSite: 'Lax' })
        }
        break;

      case false:
        if (agritelPlusCookie === true && valueAgritelPlusCookie === 'true') {
          this.isClose = true;
        } else {
          this.cookieService.set("hiddenBadgeAFPlus", "true", { expires: 90, sameSite: 'Lax' })
        }
        break;
    }
  }


  getIsConnected(): boolean {
    return User.GlobalFunctionsUser.GetIsConnected();
  }

  getUserIdProfil() {
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        switch (value.idProfil) {
          case 1:
            this.image = "../../assets/images/logo/agritel-plus/small_agritel_plus_logomark_full colour_rgb.png";
            this.numero = "07 66 39 29 79";
            this.text = "Une question concernant votre abonnement ? Contactez notre service commercial ! ";
            this.isAgritelFarmerPlus = false;
            break;
          case 2:
          case 3:
          case 4:
            this.image = "../../assets/images/svg/phone-call-call-svgrepo-com.svg";
            this.text = "Vos questions d'abonnement";
            this.hotlinePhoneNumber = "07 66 39 29 79";
            this.secondaryText = "Vos questions de marchés";
            this.numero = "01 53 05 57 09";

            this.isAgritelFarmerPlus = true;
            break;
        }
      },
      error: (err) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {},
    })
  }

}
