import { GlobalEnums } from "../global";

export class Product {
  constructor(typeProduct: GlobalEnums.TypeProductEnum, image: string, name: string){
    this.typeProductEnum = typeProduct;
    this.image = image;
    this.name = name;
  }

  public typeProductEnum: GlobalEnums.TypeProductEnum;
  public image: string = '';
  public name: string = '';
  public isPopup: boolean = false;
  public isSelected: boolean = false;  
}
