<div class="product {{orangeBorderProduct}}" (click)="ClickCheckbox2($event)" id="divProduct_{{product.typeProductEnum}}{{popup}}">
  <figure class="item inlineBlock figureProduct">
    <img src="assets/images/{{product.image}}" height="40" width="40" title="{{product.name}}" />
    <figcaption class="captionCulture whiteLabel bold">{{product.name}}</figcaption>
    <div class="pretty p-icon p-round p-smooth p-jelly radioButtonProduct">
      <input type="checkbox" id="ckProduct_{{product.typeProductEnum}}{{popup}}" (click)="ClickCheckbox($event)" />
      <div class="state p-success">
        <i class="icon mdi mdi-check"></i>
        <label></label>
      </div>
    </div>
  </figure>
</div>
