import { HttpContextToken } from '@angular/common/http';
import { Component, Injectable, Input, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalParams } from '../../../../global';
import { PriceChartFuturesComponent } from '../charts/price_chart/price_chart_futures/price_chart_futures.component';

@Component({
  selector: 'product_price_line',
  templateUrl: './product_price_line.component.html',
  styleUrls: ['./product_price_line.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})

@Injectable()
export class ProductPriceLineComponent   implements OnInit {
  @Input() productName: string = '';
  @Input() productCode: string = '';  
  @Input() productType: string;
  @Input() cellCssClass: string;
  @Input() unit: string;
  @Input() index: number;
  @Input() bgColorRow: string;
  @Input() codeExpiry: string;
  @Input() textExpiry: string;
  @Input() multiplier: number;
  @Input() expiry: string;
  @Input() lastRowBorderLeft: string;
  @Input() lastRowBorderRight: string;  
  @Input() variation: string;
  @Input() bgColorVariation: string;
  @Input() variationSign: string;
  @Input() price: number;
  @Input() dateExpiry: Date;
  @Input() rowIndex: number;
  monthNumberExpiry: string;
  yearExpiry: number;
      
  constructor(public dialog: MatDialog) {
  }

  @ViewChild('#productPricesLines_Placeholder', { read: ViewContainerRef })
  public productPricesLines_Placeholder: ViewContainerRef;

   ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ImgGraphClick() {
    this.OpenDialogChartFutures(this.productCode, this.productName, this.codeExpiry, this.textExpiry, this.multiplier)
  }

  OpenDialogChartFutures(productCode: string, productName: string, codeExpiry: string = '', textExpiry: string = '', multiplier: number): void {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);

    const dialogRef = this.dialog.open(PriceChartFuturesComponent, {
      width: '1000px',
      position: {},
      panelClass: 'custom-dialog-container',
      disableClose: false
    });

    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.productCode = productCode;
    dialogRef.componentInstance.productName = productName;
    dialogRef.componentInstance.multiplier = multiplier;

    if (codeExpiry)
      dialogRef.componentInstance.codeExpiry = codeExpiry;

    if (textExpiry)
      dialogRef.componentInstance.textExpiry = textExpiry;

    dialogRef.componentInstance.pricesType = "Futures";
    dialogRef.componentInstance.unit = 'Prix (' + this.unit + ')';
  }

  LoadData() {
  }
}
