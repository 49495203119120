<div class="keypoints-container">
  <!-- TITLE CONTAINER -->
  <div class="keypoints-title-header">
    <img src="assets/images/lightbulb.png" width="40"/>
    <div class="fontWeight700 title">{{titleComponent}}</div>
  </div>
  <!--  END TITLE CONTAINER -->
  <div class="keypoints-content-container">
    <ul class="keypoints-text-container">
      @for (item of keyPointsArray; track item) {
        <li class="keypoints-title">
          <p class="keypoints-title fontWeightBold"> {{item.title + " : " &emsp; }} </p>
          <p class="keypoints-item">{{item.text}}</p>
        </li>
      }
    </ul>
  </div>
</div>