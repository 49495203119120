<div id="divPlanDetails" class="divPlanDetails">
  <div id="divPlanDetails_background" class="backgroundColor_Plan{{planId}}">
    <label class="lblPlanDetailsTitle">{{planName}}</label>
  </div>
  <div class="backgroundColor_detailPlan">
    <div style="float:left;padding-left:140px;">
      € HT
    </div>
    <div style="float:left;">
      <label class="lbPricePlan textColor_Plan{{planId}}">{{monthlyPrice}}</label>/mois
    </div>
    <div class="newRow">
      <label>Facturation annuelle</label>
    </div>
    <div class="newRow pricing_content">
      @if (planId==1) {
        <ul class="ul_1 et_pb_pricing">
          <li>
            <span>Tableau de bord</span>
          </li>
          <li>
            <span>Alertes personalisables</span>
          </li>
          <li>
            <span>Cotation Euronext et physiques</span>
          </li>
          <li>
            <span>Décryptage hebdomadaire du marché</span>
          </li>
          <li>
            <span>Tendances & avis d'expert</span>
          </li>
          <li>
            <span>Stratégie de vente</span>
          </li>
        </ul>
      }
      @if (planId==2) {
        <ul class="ul_2 et_pb_pricing">
          <li>
            <span>Offre <b>"Garder le cap"</b></span>
          </li>
          <li class="add">
            <span>Recommandations personnalisées</span>
          </li>
          <li class="add">
            <span>Simulateur</span>
          </li>
          <li class="add">
            <span>Suivi de performances</span>
          </li>
          <li class="add">
            <span>Saisie des ventes</span>
          </li>
          <li class="add">
            <span>Module marché à terme</span>
          </li>
        </ul>
      }
      @if (planId==3) {
        <ul class="ul_3 et_pb_pricing">
          <li class="liPlan3">
            <span>Offre <b>"Ma recommandation"</b></span>
          </li>
          <li class="add longText">
            <span>Echanges & coaching privé avec un expert FARMER</span>
          </li>
        </ul>
      }
      <div class="form-actions">
        <button type="button" id="btn-block-select-plan" class="btn btn-block-select-plan backgroundColor_Plan{{planId}} textUpperCase" (click)="OpenPaymentPage(planId)">Choisir cette formule</button>
      </div>
    </div>
  </div>
</div>
