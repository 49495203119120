import { ViewContainerRef } from "@angular/core";
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums, GlobalParams } from "../global";
import { User } from "../tools/User";

export namespace UI {
  export class GlobalFunctionsUI {
    public static showLegalMentions: boolean = true;

    public static SetShowLegalMentions(show: boolean) {
      if (show)
        $("#legalMentions").show();
      else
        $("#legalMentions").hide();
    }

    public static GetShowLegalMentions(): boolean {
      return this.showLegalMentions;
    }

    public static ShowHideMenu() {
      var fullOpacityClass = 'fullOpacity';
      var noOpacityClass = 'noOpacity';
      var menu = document.getElementById("divMainMenu");

      if (~menu.className.indexOf(fullOpacityClass)) {
        menu.className = menu.className.replace(fullOpacityClass, noOpacityClass);
      } else {
        menu.className = menu.className.replace(noOpacityClass, fullOpacityClass);
      }

      this.ToggleArrow();
    }

    public static HideMenu() {
      var fullOpacityClass = 'fullOpacity';
      var noOpacityClass = 'noOpacity';
      var menu = document.getElementById("divMainMenu");

      if (~menu.className.indexOf(noOpacityClass)) {
        this.ToggleArrow();
      }

      menu.className = menu.className.replace(noOpacityClass, fullOpacityClass);
    }

    public static ToggleArrow() {
      var upClass = 'toggle-up';
      var downClass = 'toggle-down';

      var arrow = document.querySelector('.imgArrowDown');

      if (~arrow.className.indexOf(downClass)) {
        arrow.className = arrow.className.replace(downClass, upClass);
      } else {
        arrow.className = arrow.className.replace(upClass, downClass);
      }
    }

    // Function to set a given theme/color-scheme
    public static SetTheme(themeName) {
      localStorage.setItem('theme', themeName);
      document.documentElement.className = themeName;
    }

    // Function to toggle between light and dark theme
    public static ToggleTheme() {
      if (localStorage.getItem('theme') === 'theme-dark') {
        this.SetTheme('theme-light');
      } else {
        this.SetTheme('theme-dark');
      }
    }

  


    public static SetContainerClass(): void {
      if (User.GlobalFunctionsUser.GetIsConnected()) {
        $("#mainContainer").addClass("container");
        $("#mainContainer").removeClass("containerLogin");
      }
      else {
        $("#mainContainer").removeClass("container");
        $("#mainContainer").addClass("containerLogin");
      }
    }



    public static SetHomeLogged_Placeholder(homeLogged_Placeholder: ViewContainerRef) {
      GlobalParams.homeLogged_Placeholder = homeLogged_Placeholder;
    }

    public static GetHomeLogged_Placeholder(): ViewContainerRef {
      return GlobalParams.homeLogged_Placeholder;
    }

    public static RefreshHeaderName() {
      $("#lbName").text(User.GlobalFunctionsUser.GetFirstName());
    }

  }
}
