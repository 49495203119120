import { Component, OnInit, ViewChild } from '@angular/core';
import { FAQService } from '../services/http/faq.service';
import { FaqTheme } from 'src/model/faq/Faq-theme';
import { NotificationService } from '../shared/components/notifications/notification.service';
import { MatAccordion } from '@angular/material/expansion';
import { HeaderService } from '../services/header/header.component.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent implements OnInit {
  constructor(private faqService: FAQService, private notificationService: NotificationService, private headerService: HeaderService) { }

  allThemeAndItems: Array<FaqTheme>;

  ngOnInit(): void {
    this.setHeaderTitle(); 
    this.getThemesAndList();
  }

  getThemesAndList() {
    this.faqService.GetThemesAndItems().subscribe({
      next: (value: any) => {
        this.allThemeAndItems = value;
      },
      error: (err: number) => {
        if (err != 401) {

        }
      },
      complete: () => { }
    })
  }

  setHeaderTitle() {
    this.headerService.setTitle("Aide");

  }
}
