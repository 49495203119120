<div class="contentSignup">
  <div class=" lblFreePeriod">
    Tester gratuitement pendant 15 jours
  </div>
  <!-- ALL THE FIELDS -->
  <div class="form-fields">

    <mat-form-field id="mat-form-field-RaisonSocialemat-form-field-RaisonSociale" class="mat-form-field mat-form-field-infix-signup">
      <mat-label>Raison sociale Ex : SARL des pains</mat-label>
      <input maxlength="50" matInput id="RaisonSociale" name="RaisonSociale"  [(ngModel)]="RaisonSociale" required="required" placeholder="Raison sociale" type="text" autocomplete="disabled">
    </mat-form-field>

    <mat-form-field id="mat-form-field-FirstName" class="mat-form-field mat-form-field-infix-signup mat-form-field-signup">
      <mat-label>Prénom</mat-label>
      <input maxlength="50" matInput id="FirstName" name="FirstName" [(ngModel)]="FirstName" required="required" placeholder="Prénom" type="text" autocomplete="disabled">
    </mat-form-field>

    <mat-form-field id="mat-form-field-LastName" class="mat-form-field mat-form-field-infix-signup mat-form-field-signup">
      <mat-label>Nom</mat-label>
      <input maxlength="50" matInput id="LastName" name="LastName" [(ngModel)]="LastName" required="required" placeholder="Nom" type="text" autocomplete="disabled">
    </mat-form-field>
    
    <mat-form-field id="mat-form-field-Email" class="mat-form-field mat-form-field-infix-signup">
      <mat-label>Email</mat-label>
      <input matInput maxlength="50"  id="Email" name="Email" [(ngModel)]="Email" required="required" placeholder="Email" type="text" autocomplete="disabled">
    </mat-form-field>

    <mat-form-field id="mat-form-field-Mobile" class="mat-form-field mat-form-field-infix-signup" >
      <mat-label>Mobile</mat-label>
      <input maxlength="15" matInput id="Mobile" name="Mobile" [(ngModel)]="Mobile" required="required" placeholder="Mobile" type="text" autocomplete="disabled">
    </mat-form-field>

    <mat-form-field class="mat-form-field mat-form-field-infix-signup">
      <mat-label>Mot de passe</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="Password" maxlength="100" id="Password"  required="required" placeholder="Mot de passe" name="Password"  autocomplete="disabled">
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword" class="buttonTogglePassword" >
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="mat-form-field mat-form-field-infix-signup">
      <mat-label>Mot de passe</mat-label>
      <input matInput [type]="hidePasswordConfirm ? 'password' : 'text'" [(ngModel)]="PasswordConfirm" maxlength="100" id="PasswordConfirm"  required="required" placeholder="Confirmation mot de passe" name="PasswordConfirm"  autocomplete="disabled">
      <button mat-icon-button matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirm" class="buttonTogglePassword">
        <mat-icon>{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

  </div>
  <!-- GENERAL TERMS AND CONDITIONS  -->
  <div class="form-conditions ">
    <div class="divAcceptConditions">
      <div class="pretty p-default">
        <input class="form-control" id="AcceptConditions" name="AcceptConditions" [(ngModel)]="AcceptConditions" required="required" type="checkbox">
        <div class="state conditions p-success conditionText">
          <i class="icon mdi mdi-check" id="iForConditions"></i>
          <label class="lblForConditions" id="lblForConditions">
            J'accepte les
          </label>
        </div>
      </div>
      <label class="lblCondition lblConditionDesktop" (click)="lblCondition_click();">
        <a id="aCGU" href="https://www.captainfarmer.com/politique-confidentialite/" target="_blank">conditions générales d'utilisation</a>
      </label>
      <div class="divCGUMobile">
        <label class="lblCondition" (click)="lblCondition_click();">
          <a id="aCGU" href="https://www.captainfarmer.com/politique-confidentialite/" target="_blank" >conditions générales d'utilisation</a>
        </label>
      </div>
      <label class="lblCondition2" (click)="lblCondition_click();">
        et de recevoir les communications en provenance de Agritel Farmer
      </label>
    </div>
    <div class=" divAcceptDataUsage" >
      <div class="pretty p-default">
        <figure class="item inlineBlock figureProduct">
          <input class="form-control" id="AcceptDataUsage" name="AcceptDataUsage" [(ngModel)]="AcceptDataUsage" required="required" type="checkbox">
          <div class="state data p-success dataText">
            <i class="icon mdi mdi-check"></i>
            <label class="lblData">
              J'accepte que mes données soient utilisées dans le cadre de ce service et de la relation commerciale qui en découle
            </label>
          </div>
        </figure>
      </div>
    </div>
  </div>

  <!-- FORM ACTION  -->




  <div class="form-action-buttons">

    <button class="buttonOutlined  width_40" [routerLink]="['/connexion']" > Retour </button>
    <!-- <a class=" buttonOutlined  width_50  btnBack orangetextbutton" (click)="OpenLoginPage();" >Retour</a> -->
    <button class="buttonFullTextBoldUppercase  width_40 btnCreateAccount" (click)="this.SignUp();">Créer mon compte</button>
</div>
    
<div id="legalMentions" class="copyright show">
  <legal_mentions></legal_mentions>
</div>

</div>