<div class="quotes-container">
  <div class="divQuotes divQuotesFutureEuronext">
    <euronext_quotes></euronext_quotes>
  </div>
  <div class="row"></div>
  <div class="divQuotes divQuotesFutureCME">
    <CME_quotes></CME_quotes>
  </div>
  <div class="row"></div>
  <div class="divQuotes divQuotesPhysical">
    <physical_quotes></physical_quotes>
  </div>
</div>