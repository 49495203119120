import { Component, Injectable, OnInit } from '@angular/core';
import { GlobalEnums } from '../../../global';
import { UI } from '../../../tools/UI';

@Component({
  selector: 'signupTeaser',
  templateUrl: './signup_teaser.component.html',
  styleUrls: ['./signup_teaser.component.css']
})

@Injectable()
export class SignupTeaserComponent   implements OnInit {
  constructor() {
  }

   ngOnInit(): void {
  }   

  ngAfterViewInit() {
  }

  OpenSignupPage() {
    UI.GlobalFunctionsUI.SetCurrentPage(GlobalEnums.SubComponentEnum.signup);
  }
}
