import { Injectable } from '@angular/core';
import { GetHistoryPriceFuturParamsRequest } from '../../../model/service/GetHistoryPriceFutureParamsRequest';
import { GetHistoryPricePhyParamsRequest } from '../../../model/service/GetHistoryPricePhyParamsRequest';
import { GetLastPricesFutureRequest } from '../../../model/service/GetLastPricesFutureRequest';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class QuotesService extends Service {
  override controller = 'Quotes';
  getHistoryPricePhyMethod: string = 'GetHistoryPricePhy';
  getHistoryPriceFutMethod: string = 'GetHistoryPriceFutur';
  getProductQuotes: string = 'GetQuotesProduct';
  getLastPricePhyMethod: string = 'GetLastPricePhy';
  getLastPriceFutureMethod: string = 'GetLastPriceFuture';
  
  ngOnInit(): void {
  }

  GetProductQuotesHistoryPhysical(getHistoryPricePhy : GetHistoryPricePhyParamsRequest): any {
    let data: any = this.HttpPost(this.controller, this.getHistoryPricePhyMethod, getHistoryPricePhy);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetProductQuotesHistoryFuturesEuronext(getHistoryPriceFut: GetHistoryPriceFuturParamsRequest): any {
    let data: any = this.HttpPost(this.controller, this.getHistoryPriceFutMethod, getHistoryPriceFut);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetProductQuotesHistoryFuturesCME(getHistoryPriceFut: GetHistoryPriceFuturParamsRequest): any {    
    let data: any = this.HttpPost(this.controller, this.getHistoryPriceFutMethod, getHistoryPriceFut);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetProductQuotes(typeProductQuotes:number): any {
    let data: any = this.HttpPost(this.controller, this.getProductQuotes, typeProductQuotes);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetLastPricePhy(requestLastPriceProducts: Array<string>): any {
    let data: any = this.HttpPost(this.controller, this.getLastPricePhyMethod, requestLastPriceProducts);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetLastPriceFutureEuronext(productCodes: Array<string>): any {
    let request: GetLastPricesFutureRequest = new GetLastPricesFutureRequest();
    request.productCodes = productCodes;
    request.typeProductQuotes = 0;

    let data: any = this.HttpPost(this.controller, this.getLastPriceFutureMethod, request);

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetLastPriceFutureCME(productCodes: Array<string>): any {
    let request: GetLastPricesFutureRequest = new GetLastPricesFutureRequest();
    request.productCodes = productCodes;
    request.typeProductQuotes = 1;

    let data: any = this.HttpPost(this.controller, this.getLastPriceFutureMethod, request);

    JSON.parse(JSON.stringify(data))

    return data;
  }
}


