import { Component, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'signupWithTeaser',
  templateUrl: './signup_with_teaser.component.html',
  styleUrls: ['./signup_with_teaser.component.scss']
})

@Injectable()
export class SignupWithTeaserComponent   implements OnInit {
  constructor() {

  }

   ngOnInit(): void {    
  }
}
