import { Company } from "../Company";

export class User {
  id: string='';
  companyId: number = 0;
  civility: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  password: string = '';
  tempPassword: string = '';
  accountId: string = '';
  phone: string = '';
  languageId: string = '';
  alertSubscriberIds: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  alternatePassword: boolean = false;
  isNotifyInformationAccepted: boolean = false;
  dateValidateNotifyInformation: Date = new Date();
  company: Company;
  idProfil: number;
}