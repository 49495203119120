<div class="form-group textCenter">
  <label class="welcomeCaptain">Bienvenue Captain !</label>
</div>
<div class="form-group textCenter">
  @if (validationState==AVSValidating) {
    <label class="confirmationMessage">Compte en cours de validation..</label>
  }
  @if (validationState==AVSValidated) {
    <label class="confirmationMessage">Votre compte a été validé.</label>
  }
  @if (validationState==AVSErrorMissingKey) {
    <label class="confirmationMessage">Clé de validation manquante</label>
  }
  @if (validationState==AVSErrorAlreadyValidated) {
    <label class="confirmationMessage">Compte déjà validé</label>
  }
  @if (validationState==AVSErrorGeneral) {
    <label class="confirmationMessage">Une erreur s'est produite</label>
  }
</div>
<div class="form-actions textCenter">
  <button type="submit" class="btn btn-block buttonNormalcase" (click)="SetCurrentPage(SCLogin);">Aller à la page connexion</button>
</div>
<div style="margin-top:30px;" class="stores">
  <div style="margin:auto;width:77%;" class="{{storeCssClassAndroid}}">
    <img src="./assets/images/download_Android.png" (click)="OpenStore(1)" style="cursor:pointer;width:335px;" title="Télécharger l'application Agritel Farmer sur Google Play Store'" />
  </div>
  <div class="row {{storeCssClassIOS}}" style="margin:auto;margin-top:10px;width:77%;">
    <img src="./assets/images/download_iOS.png" (click)="OpenStore(2)" style="cursor: pointer;" title="Télécharger l'application Agritel Farmer sur l'Apple Store'" />
  </div>
</div>
