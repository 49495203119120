<div class="row container-my_plan {{backgroundColorClass}}" style="margin-left:35px;margin-bottom:20px;">
  <div class="col-xs-6 p-3 myOffer">
    <label class="myOfferTitle">
      Mon offre<br />
    </label>
    <label class="myOfferLabel myOfferLabel_{{planId}}">
      {{planName}}
    </label>
  </div>
  <div class="col-xs-6 p-3 manageMyOffer">
    @if (isTestProfil) {
      <div class="testProfile">
        {{test}}
      </div>
    }
    <!--<div class="myPlanKnowMore">
    <label (click)="OpenPlanDetail();" id="lbKnowMore_{{planId}}" class="lbKnowMore">En savoir plus</label>
    <img (click)="OpenPlanDetail();" id="imgArrowDown_{{planId}}" class="imgArrowDown" src="assets/images/arrow-down-white.png" />
  </div>-->
</div>
</div>
