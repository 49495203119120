import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums } from '../../../global';
import { UI } from '../../../tools/UI';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { UserService } from '../../services/http/user.service';

@Component({
  selector: 'myPlanLargeHorizontal',
  templateUrl: './my_plan_large_horizontal.component.html',
  styleUrls: ['./my_plan_large_horizontal.component.scss']
})

@Injectable()
export class myPlanLargeHorizontalComponent implements OnInit {
  planId: number;
  planName: string = '';
  backgroundColorClass: string = '';
  isSelected: boolean = false;
  test: string = '';
  isTestProfil: boolean;

  SCMyPlanSelection: GlobalEnums.SubComponentEnum = GlobalEnums.SubComponentEnum.myPlanSelection;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private notifyService: NotificationService) { }
  ngOnInit(): void {    
  }

  ngAfterViewInit() {
    this.userService.GetIdProfil().subscribe(() => {
      //this.planId = data.idProfil;
      this.test = ((this.planId == 2 || this.planId == 4) && this.planId == 4) ? "Version test 15 jours" : "";
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }

  OpenPlanDetail() {
    this.CloseAllPlansExcept(this.planId);

    var lb = document.getElementById("lbKnowMore_" + this.planId);
    var img = $("#imgArrowDown_" + this.planId);

    if (lb.classList.contains("blackLabel")) {
      lb.classList.remove("blackLabel");
      img.attr("src", "assets/images/arrow-down-white.png");
    }
    else {
      lb.classList.add("blackLabel");
      img.attr("src", "assets/images/arrow-down-black.png");
    }            
  }

  CloseAllPlansExcept(idExcept: number) {
    for (var i = 1; i < 5; i++) {
      if (i != idExcept) {
        $("#lbKnowMore_" + i).removeClass("blackLabel");
        $("#imgArrowDown_" + i).attr("src", "assets/images/arrow-down-white.png");
      }
    }
  }

  CloseAllPlans() {
    for (var i = 1; i < 5 ;i++) {
      $("#lbKnowMore_" + i).removeClass("blackLabel");
      $("#imgArrowDown_" + i).attr("src", "assets/images/arrow-down-white.png");
    }
  }

  SetCurrentPage(id: GlobalEnums.SubComponentEnum): void {
    UI.GlobalFunctionsUI.SetCurrentPage(id);
  }
}
