import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalFunctions } from '../../../global';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { UserService } from '../../services/http/user.service';
import { UXService } from '../../services/http/UX.service';
import { myPlanLargeHorizontalComponent } from '../my_plan_large_horizontal/my_plan_large_horizontal.component';

@Component({
  selector: 'myPlanSelection',
  templateUrl: './my_plan_selection.component.html',
  styleUrls: ['./my_plan_selection.component.css']
})

@Injectable()
export class myPlanSelectionComponent implements OnInit {  
  constructor(private resolver: ComponentFactoryResolver, private notifyService: NotificationService, private companyService: CompanyService,
              private userService: UserService, private uxService: UXService) { }

  rightEnd: string = '';
  expireRenew: string = '';
  IdProfil: number;
  planHasExpired: boolean;

  @ViewChild('myPlanCurrentOffer_Placeholder', { read: ViewContainerRef })
  public myPlanCurrentOffer_Placeholder: ViewContainerRef;

  ngOnInit(): void {
    this.companyService.PlanHasExpired().subscribe(data => {
      this.planHasExpired = data;
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      }); 
  }

  ngAfterViewInit() {
    this.userService.GetIdProfil().subscribe((data) => {
      this.IdProfil = data.idProfil;

      this.LoadData();
      this.GetRightEnd();
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }

  LoadData() {
    if (this.planHasExpired) {
      this.expireRenew = "a expiré";
    }
    else {
      this.expireRenew = this.IdProfil == 4 ? "expirera" : "renouvelera";
    }

    // Affichage de l'offre courante, sauf si l'offre est expirée
    if (!this.planHasExpired) {
      this.LoadPlanCurrentOffer();
    }    
  }

  LoadPlanCurrentOffer() {
    let factory: ComponentFactory<myPlanLargeHorizontalComponent> = this.resolver.resolveComponentFactory(myPlanLargeHorizontalComponent);
    let myPlanCurrentOffer: ComponentRef<myPlanLargeHorizontalComponent> = this.myPlanCurrentOffer_Placeholder.createComponent(factory);

    myPlanCurrentOffer.instance.planId = this.IdProfil;
    myPlanCurrentOffer.instance.planName = GlobalFunctions.GetPlanNameFromId(this.IdProfil);
    myPlanCurrentOffer.instance.backgroundColorClass = "backgroundColor_Plan" + this.IdProfil;
    myPlanCurrentOffer.instance.isTestProfil = (this.IdProfil == 4);
  }

  GetRightEnd() {
      this.companyService.GetRightEnd().subscribe(data => {
        this.rightEnd = data.rightEnd;
        console.log(this.rightEnd)
        $("#lblRightEnd").text(this.rightEnd);
      },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }
}





