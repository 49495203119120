<tr id="{{rowIndex}}" style="{{'background-color: #' + bgColorRow}}">
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="{{'text-align:center;' + lastRowBorderLeft}}">
    <label style="color: #000000;">{{textExpiry}}</label>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="text-align:center;">
    <label id="lblPriceFuture{{productType}}_{{productCode + '_' + codeExpiry}}" style="color:#000000;font-variant-numeric:tabular-nums;">{{price}}</label>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="text-align:center;padding-left:0;padding-right:0;">
    <div>
      <label id="{{'lblVariationSignFuture' + productType + '_' + productCode + '_' + codeExpiry}}" style="{{'padding-left:6px;width:18px;height:24px;color:#FFF;vertical-align: middle;background-color:#' + bgColorVariation + ';border-top-left-radius:10px;border-bottom-left-radius:10px;font-size:1.0em;font-variant-numeric: tabular-nums;margin-bottom:0;margin-top:0;'}}">{{variationSign}}</label>
      <label id="{{'lblVariationValueFuture' + productType + '_' + productCode + '_' + codeExpiry}}" style="{{'color:#FFF;width:57px;vertical-align:middle;margin-top:0;border-top-right-radius:10px;padding-right:8px;border-bottom-right-radius:10px;font-size:1.0em;font-variant-numeric:tabular-nums;margin-bottom:0;background-color:#' + bgColorVariation}}">{{variation}}</label>
    </div>
  </td>
  <td class="{{cellCssClass}}" attr.rowid="{{rowIndex}}" style="{{'text-align:center;' + lastRowBorderRight}}">    
    <mat-icon class="imgProductPriceFuture{{productType}} material-icons-chart" attr.codeExpiry="{{codeExpiry}}" attr.textExpiry="{{textExpiry}}" attr.productName="{{productName}}" attr.productCode="{{productCode}}" (click)="ImgGraphClick()" aria-hidden='false' aria-label='insert_chart' title='Graphique' fontIcon='insert_chart'></mat-icon>
  </td>
</tr>
