<div class="container">
  <div class="left-menu">
    <leftMenu></leftMenu>
  </div>
  <div class="component-container">
  <app-account-nav></app-account-nav>
    <div class="row container-my_plan_page d-flex justify-content-center">
      <div class="col-xs-6 p-3 myPlanSelection">
        <myPlanSelection></myPlanSelection>
      </div>
      <div class="col-xs-6 p-3 contactAdvisor">
        <contactAdvisor [page]="page"></contactAdvisor>
      </div>
    </div>
    <div class="row container-my_plan_page d-flex justify-content-center">
      <div class="plan">
        <ng-container #myPlan1_Placeholder></ng-container>
      </div> 
      <div class="plan notFirstPlan">
        <ng-container #myPlan2_Placeholder></ng-container>
      </div> 
      <div class="plan notFirstPlan">
        <ng-container #myPlan3_Placeholder></ng-container>
      </div>
    </div>
  </div>
</div>


