<div class="container-signup_teaser">
  <div class="container-img-sign-up">
    <img src="assets/images/logo/agritelFarmer/agritelFullColoredLogoWithText.png" height="100" width="222" alt="Agritel Farmer">
  </div>
  <div>
    <label class="lblDescription">
      La solution pour vendre au bon moment
    </label>
    <label class="lblIntroduction">
      "J'ai fait un essai concluant avec Agritel Farmer, et je me fie désormais à leurs conseils pour le rythme de mes ventes.
      <br />
      L'analyse de marché est un métier à part entière."
      <br />
      <br />
    </label>
    <label class="lblFarmer">
      Philippe Grave,
      Agriculteur dans l'Aisne (02)
    </label>
  </div>
</div>
