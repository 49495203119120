<div class="container-notice">
  <div class="notice-title">
    <h4>Conditions d'utilisations et de ventes</h4>
  </div>
  <hr>
  <div class="notice-content-container">
    <div class="">
      <form [formGroup]="formTermsOfService" (ngSubmit)="onSubmit()">
        <div class="notice-explaination-container">
          <span class="notice-explaination">
            <p>Qui dit nouveau service et nouveaux contenus dit également nouvelles conditions générales de ventes.
              Vous pourrez consulter et accepter tout cela lors de votre première connexion et ensuite accéder au
              service.
            </p>
            <p class="notice-explaination-bold">
              A noter que le tarif en vigueur, depuis le 1er août 2023, pour votre abonnement en cours ne change pas
              dans l’immédiat.
            </p>

          </span> 
        </div>
        <div class="form-action ">
          <label for="termsOfService">Je reconnaîs avoir pris connaissance des<a
              href="https://files.captainfarmer.com/captainfarmer/Terms_And_Conditions/CGV_Agritel_Farmer.pdf"
              target="_blank"> conditions générales de vente </a> et de leur préambule. 
              Je les accepte comme partie intégrante de mon contrat <mat-checkbox class="example-margin" color="primary" name="termsOfService"
              formControlName="isCGVValidated"></mat-checkbox></label>
        </div>
        <hr>
        <div class="form-action-notice">
          <button type="submit" [disabled]="formTermsOfService.invalid" mat-button [mat-dialog-close]="true"
            class="buttonFull">Accepter</button>
        </div>
      </form>
    </div>
  </div>
</div>