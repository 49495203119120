import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums } from '../../../global';
import { OS } from '../../../tools/OS';
import { UI } from '../../../tools/UI';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ConnexionService } from '../../services/connexion/connexion.service';

@Component({
  selector: 'validateAccount',
  templateUrl: './validate_account.component.html',
  styleUrls: ['./validate_account.component.css']
})

@Injectable()
export class ValidateAccountComponent implements OnInit {
  title = '';

  form: FormGroup | undefined;

  storeCssClassAndroid = '';
  storeCssClassIOS = '';

  validateKey = '';
  validationState: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.validating;

  isLoginServiceError = false;
  isSuccessful = true;
  isSignUpFailed = false;
  errorMessage = '';

  SCLogin: GlobalEnums.SubComponentEnum = GlobalEnums.SubComponentEnum.login;

  AVSValidating: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.validating;
  AVSValidated: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.validated;
  AVSErrorMissingKey: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.errorMissingKey;
  AVSErrorAlreadyValidated: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.errorAlreadyValidated;
  AVSErrorGeneral: GlobalEnums.AccountValidationStateEnum = GlobalEnums.AccountValidationStateEnum.errorGeneral;

  constructor(private connexionService: ConnexionService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    let osType = OS.GlobalFunctionsOS.AndroidOrIOS().toLowerCase();

    if (osType == 'android') {
      this.storeCssClassAndroid = '';
      this.storeCssClassIOS = 'hide';
    }
    else if (osType == 'ios') {
      this.storeCssClassAndroid = 'hide';
      this.storeCssClassIOS = '';
    }

    this.validateKey = new URLSearchParams(window.location.search).get('validateKey');

    $("#mainContainer").removeClass("container");
    $("#mainContainer").removeClass("containerLogin");
    $("#mainContainer").addClass("container-confirmation");

    this.ValidateAccount();
  }

  ValidateAccount() {
    if ((!this.validateKey) || this.validateKey.trim().length == 0) {
      this.validationState = this.AVSErrorMissingKey;
      this.notifyService.ShowError("Clé de validation manquante.", "");
      return;
    }

    this.connexionService.ValidateAccount(this.validateKey, this);
  }

  OpenStore(storeId: number) {
    OS.GlobalFunctionsOS.OpenStore(storeId);
  }

  SetCurrentPage(id: GlobalEnums.SubComponentEnum): void {
    if (id == GlobalEnums.SubComponentEnum.login) {
      $("#mainContainer").addClass("container");
      $("#mainContainer").addClass("containerLogin");
      $("#mainContainer").removeClass("container-confirmation");
    }

    UI.GlobalFunctionsUI.SetCurrentPage(id);
  }
}
