import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { GraphOptions } from '../../../../../../Model/GraphOptions';
import { GetHistoryPricePhyParamsRequest } from '../../../../../../model/service/GetHistoryPricePhyParamsRequest';
import { Connection } from '../../../../../../tools/Connection';
import { ProductQuotesTypeEnum } from '../../../../../../tools/enums';
import { Graph } from '../../../../../../tools/Graph';
import { HighchartsService } from '../../../../../services/charts/highcharts.service';
import { HighstockService } from '../../../../../services/charts/highstock.service';
import { QuotesService } from '../../../../../services/http/quotes.service';
import { PriceChartComponent } from '../price_chart.component';

@Component({
  selector: 'price_chart_physical',
  templateUrl: './price_chart_physical.component.html',
  styleUrls: ['./price_chart_physical.component.scss']
})

@Injectable()
export class PriceChartPhysicalComponent extends PriceChartComponent implements OnInit {
  override dialogRef: MatDialogRef<PriceChartPhysicalComponent>;
  cssClass1w: string = this.defaultCssClassInterval + " " + this.cssClassIntervalFirst;
  cssClass1m: string = this.defaultCssClassInterval + " " + this.cssClassIntervalSelected;
  cssClass3m: string = this.defaultCssClassInterval;
  cssClass6m: string = this.defaultCssClassInterval;
  cssClassMax: string = this.defaultCssClassInterval;
  chart;

  constructor(private quotesService: QuotesService, _highchartsService: HighchartsService, _highstockService: HighstockService) {
    super(_highchartsService, _highstockService);

    this.productQuotesType = ProductQuotesTypeEnum.Physical;
  }

  eventsSubject: Subject<void> = new Subject<void>();

  override ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.unit = 'Prix (€/t)';

    let graphOptions: GraphOptions = new GraphOptions();
    graphOptions.interval = this.defaultInterval;
    graphOptions.isCandleStick = false;
    graphOptions.isFirstLoad = true;
    graphOptions.YAxisText = this.unit;

    this.RefreshGraph(graphOptions);
  } 

  override RefreshGraph(graphOptions: GraphOptions) {
    var options:any = {
      chart: {
        type: 'line',
        zoomType: 'x'
      },
      title: {
        text: 'Prix physiques'
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: graphOptions.YAxisText
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [{
        name: '',
        data: []
      }]
    };

    options.title.text = this.productName;

    this.graphTitle = this.productName;

    const currentDate: Date = new Date();
    const startDate: Date = Graph.GlobalFunctionsGraph.GetStartDateForInterval(graphOptions.interval);
    let request: GetHistoryPricePhyParamsRequest = new GetHistoryPricePhyParamsRequest();
    request.Interval = graphOptions.interval;
    request.Code = this.productCode;
    request.StartDate = startDate;
    request.EndDate = currentDate;

    this.quotesService.GetProductQuotesHistoryPhysical(request).subscribe({
      next: (data) => {
        this.data = data;
        this.chart = this.highchartsService.CreateChartLinear(this.charts.nativeElement, data, this.productName, false, request.Interval, graphOptions.isFirstLoad, graphOptions.YAxisText);      
      }, error: () => {
        this.ClosePopup();
        document.body.style.overflowY = "hidden";
        Connection.GlobalFunctionsConnection.Logout();
      }
    });
  }

  ExportGraph(event) {
    this.chart.exportChart();
  }
}
