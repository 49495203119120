import { Injectable } from '@angular/core';
import { Service } from './service';
import { FaqTheme } from 'src/model/faq/Faq-theme';
import { Observable } from 'rxjs';
import { FaqItem } from 'src/model/faq/Faq-item';

@Injectable({
  providedIn: 'root'
})

export class FAQService extends Service {
  override controller = 'FAQ';

  GetThemes(): any {
    let data: Observable<FaqTheme> = this.HttpPostGeneric(this.controller, "GetThemes", null)

    JSON.parse(JSON.stringify(data))

    return data;
  }

  GetListItemsInTheme(idTheme: number): any {
    let data: Observable<FaqItem> = this.HttpPostGeneric(this.controller, "GetListItemsInTheme", idTheme)

    JSON.parse(JSON.stringify(data))

    return data; 
  }

  GetThemesAndItems() : any {
    let data : Observable<FaqTheme> = this.HttpPostGeneric(this.controller , "GetThemesAndItems", null)
    JSON.parse(JSON.stringify(data))

    return data; 
  }
}