import { HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GlobalEnums, GlobalParams } from '../../../global';
import { LoginInformations } from '../../../model/LoginInformations';
import { LogInResponse } from '../../../model/LogInResponse';
import { UI } from '../../../tools/UI';
import { User } from '../../../tools/User';
import { InformationNoticeComponent } from '../../account/information_notice/information_notice.component';
import { ValidateAccountComponent } from '../../account/validate_account/validate_account.component';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { TabMyProductsComponent } from '../../farm/tab_my_products/tab_my_products.component';
import { CompanyService } from '../http/company.service';
import { ConnectionService } from '../http/connection.service';
import { GlobalFunctionsToken } from 'src/tools/Token';


@Injectable({
  providedIn: 'root'
})

export class ConnexionService {
  constructor(private notifyService: NotificationService, private cookieService: CookieService, public dialog: MatDialog,
    private connectionService: ConnectionService, private companyService: CompanyService, private router: Router, private route: ActivatedRoute,) {
  }

  tabMyProductsComponentNonPopup: TabMyProductsComponent;
  userNotified: boolean;
  // returnUrl : string = ""; 

  OpenDialogInformationNotice(): void {
    const dialogRef = this.dialog.open(InformationNoticeComponent, {
      width: '800px',
      position: {
        left: '40vw'
      },
      panelClass: 'middle',
      disableClose: true
    });

    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.isPopup = true;
    dialogRef.afterClosed().subscribe(result => {
      this.companyService.AcceptNoticeInformation().subscribe(() => {
        const requestIsFirstConnexion: any = { isFirstConnexion: false };

        this.companyService.SetFirstConnection(requestIsFirstConnexion).subscribe(() => {
          ConnexionService.SetCurrentPage(GlobalEnums.SubComponentEnum.quotes);

          this.OpenDialogProducts(User.GlobalFunctionsUser.GetDefaultProducts());
        },
          (err: { status: number; }) => {
            if (err.status !== 401) {
              this.notifyService.ShowError("Une erreur s'est produite", "");
            }
          });
      });
    });
  }

  OpenDialogProducts(defaultProducts: number = null): void {
    const dialogRef = this.dialog.open(TabMyProductsComponent, {
      width: '600px',
      height: '610px',
      position: {
        left: '43vw'
      },
      panelClass: 'middle',
      disableClose: true
    });


    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.isPopup = true;

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public Login(request: LoginInformations) {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // console.log(this.returnUrl)

    this.connectionService.loginUser(request).subscribe({
      next: (logInResponse: LogInResponse) => {

        this.userNotified = logInResponse.isCGVAccepted; 

        this.cookieService.set("token", logInResponse.token, 1);

        GlobalParams.BYPASS_HTTPINTERCEPTOR = new HttpContextToken(() => false);

        document.body.style.overflowY = "auto";

        // User.GlobalFunctionsUser.SetIsFirstConnection(logInResponse.isFirstConnection);
        GlobalFunctionsToken.SetConnectionToken(logInResponse.token);
        UI.GlobalFunctionsUI.SetCurrentPage(GlobalEnums.SubComponentEnum.quotes);
        User.GlobalFunctionsUser.SetDefaultProducts(logInResponse.products);
        // User.GlobalFunctionsUser.SetIsNotifyInformationAccepted(logInResponse.isNotifyInformationAccepted);
        User.GlobalFunctionsUser.SetEmail(request.Email);
        User.GlobalFunctionsUser.SetFirstName(logInResponse.firstName);
        User.GlobalFunctionsUser.SetLastName(logInResponse.lastName);
      },
      error: (err: { error: { detail: string; }; }) => {

        if (err.error.detail == "InformationUser") {
          this.notifyService.ShowError("Email ou mot de passe incorrect", "");
        }
        else if (err.error.detail == "EmailValidation") {
          UI.GlobalFunctionsUI.SetCurrentPage(GlobalEnums.SubComponentEnum.confirmation);
        }
        else {
          this.notifyService.ShowError("Erreur de connexion", "");
        }
      },
      complete: () => {
        this.showPolicies(this.userNotified);
        const redirectingUrl = localStorage.getItem("redirectedUrl");
        if (redirectingUrl != null) {
          this.router.navigateByUrl(redirectingUrl);
        } else {
          this.router.navigateByUrl('cotations');
        }

      }
    })
  }

  public ValidateAccount(validateKey: string, validateAccountComponent: ValidateAccountComponent): void {
    this.connectionService.ValidateAccount(validateKey).subscribe(() => {
      this.notifyService.ShowSuccess("Votre compte a été validé.", "");
      validateAccountComponent.validationState = GlobalEnums.AccountValidationStateEnum.validated;
    },
      err => {
        if (err.error.detail == "UnknownKey") {
          this.notifyService.ShowError("Votre compte a déjà été validé.", "");
          validateAccountComponent.validationState = GlobalEnums.AccountValidationStateEnum.errorAlreadyValidated;
        }
        else {
          this.notifyService.ShowError("Une erreur s'est produite lors de la validation de votre compte.", "");
          validateAccountComponent.validationState = GlobalEnums.AccountValidationStateEnum.errorGeneral;
        }
      }
    );
  }

  public static SetCurrentPage(id: GlobalEnums.SubComponentEnum): void {
    UI.GlobalFunctionsUI.SetCurrentPage(id);
  }

  public showPolicies(hasAcceptPolicies: boolean) {
    if (hasAcceptPolicies === false) {
      this.OpenDialogInformationNotice();
    }
  }

}
