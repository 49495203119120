import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from "@angular/router"
import { User } from "src/tools/User"; 

export const authGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,) => {
  const isConnectedUser = User.GlobalFunctionsUser.GetIsConnected(); 
  const router = inject(Router);

  // If user has a token (is connected) then I will let him have acces to the route ; 
  if (isConnectedUser) {
    return true;
  }
  router.navigateByUrl('connexion')
  return false;
}